import { Component, HostBinding } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { EnvironmentService } from '../common/services/environment/environment.service';
import { Observable } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { AlertPromptComponent } from '../components/alert-prompt/alert-prompt.component';
import { AlertPromptService } from '../common/services/alert-prompt/alert-prompt.service';
import { AlertPromptLogout } from '../common/model/alert-prompt/alert-prompt-logout';

@Component({
  selector: 'app-navigation',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule, AlertPromptComponent],
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  isDrawerOpen$: Observable<boolean>;
  isAlertPromptVisible$: Observable<boolean>;
  alertPromptLogout = new AlertPromptLogout();

  @HostBinding('class.drawer-open')
  set isDrawerOpen(isOpen: boolean) {
    this.environmentService.isNavDrawerOpen = isOpen;
  }

  get isDrawerOpen() {
    return this.environmentService.isNavDrawerOpen;
  }

  constructor(
    private environmentService: EnvironmentService,
    private authService: MsalService,
    private alertPromptService: AlertPromptService
  ) {
    this.isDrawerOpen$ = this.environmentService.isNavDrawerOpen$;
    this.isAlertPromptVisible$ = this.alertPromptService.getAlertPromptVisibility();
  }

  toggleNavDrawer() {
    const { isDrawerOpen } = this;
    this.isDrawerOpen = !isDrawerOpen;
  }

  logout() {
    this.alertPromptService.openAlertPrompt();
  }

  /**
   * Alert Prompt Handling
   */
  public alertPromptCancel() {
    this.hideAlertPrompt();
  }

  public alertPromptContinue() {
    this.hideAlertPrompt();
    this.authService.logoutRedirect();
  }

  private hideAlertPrompt() {
    this.alertPromptService.dismissAlertPrompt();
  }
}
