export const API_RETRY_LIMIT = 3; // number of times to retry failed API requests

export const DEFAULT_THEME = {
  backgroundColor: '#ffffff',
  backgroundColorDark: '#000000',
  textColor: '#000000',
  textColorDark: '#F6F6F6',
  tintColor: '#0f66bd',
  tintColorDark: '#197adc',
  tintActiveColor: '#2488d0',
  tintActiveColorDark: '#2488d0',
};
