import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CampaignService } from '../common/services/campaign/campaign.service';
import { DEFAULT_THEME } from '../common/constants/general';
import { Campaign } from '../common/model/campaign';
import { ServiceGroup, ServicesList } from '../common/model/service';
import { Vaccine } from '../common/model/vaccine';
import { TranslateModule } from '@ngx-translate/core';
import { LocationGroup } from '../common/model/location-group';
import { LocationGroupService } from '../common/services/location-group/location-group.service';
import { ApiError } from '../common/model/api-error';
import { NavigationComponent } from '../navigation/navigation.component';
import { ThemeExampleComponent } from '../components/theme-example/theme-example.component';
import { AlertType } from '../common/constants/alert';
import { environment } from 'src/environments/environment';
import { DateTime } from 'luxon';
import { Language } from '../common/model/language';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { LocationGroupParent } from '../common/model/location-group-parent';

@Component({
  selector: 'app-campaign',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NavigationComponent,
    ThemeExampleComponent,
    AccordionModule,
  ],
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss'],
})
export class CampaignComponent implements OnInit {
  campaign?: Campaign;
  campaignId?: Campaign['campaignId'];
  form: FormGroup = new FormGroup({});
  isReadOnly = false; // set to true if existing campaign, false if create
  languages: Language[] = [
    {
      localeCode: 'en',
      localeTC: 'LANGUAGE.ENGLISH',
    },
    {
      localeCode: 'es',
      localeTC: 'LANGUAGE.SPANISH',
    },
  ];
  locationGroupParents?: LocationGroupParent[];
  locationGroups?: LocationGroup[];
  loading = false;
  servicesList?: ServicesList[];
  serviceGroups?: ServiceGroup[];
  vaccines?: Vaccine[];
  error?: ApiError;
  siteUrl = environment.siteUrlWithSlash;
  qrCode?: string;

  get dateCreated() {
    const { campaign } = this;
    if (campaign?.createdDate) {
      return DateTime.fromISO(campaign.createdDate).toFormat(
        "MMM d, yyyy 'at' h:mm a"
      );
    }

    return '--';
  }

  get dateUpdated() {
    const { campaign } = this;
    if (campaign?.updatedDate) {
      return DateTime.fromISO(campaign.updatedDate).toFormat(
        "MMM d, yyyy 'at' h:mm a"
      );
    }

    return '--';
  }

  get status() {
    // status is the opposite of our disabled field
    return !this.form.get('disabled')?.value;
  }

  set status(disabled: boolean) {
    this.form.get('disabled')?.setValue(!disabled);
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private campaignService: CampaignService,
    private locationGroupService: LocationGroupService
  ) {}

  ngOnInit() {
    this.route.params
      .subscribe((param) => {
        const id = param['campaignId'];
        if (id !== 'create') {
          this.campaignId = id;
        }

        if (this.campaignId) {
          this.isReadOnly = true;
          this.getCampaign();
          this.getCampaignQrCode();
        } else {
          this.isReadOnly = false;
        }
      })
      .unsubscribe();

    this.setFormData();
    this.setLocationGroupParents();
    this.setLocationGroups();
    this.setServicesList();
    this.setServices();
    this.setVaccines();
  }

  async cancel() {
    await this.router.navigateByUrl('/campaigns');
  }

  /**
   * Download QR code for current campaign
   * Only available after campaign creation
   */
  downloadQrCode() {
    const downloadLink = this.qrCode;
    if (downloadLink) {
      const link = document.createElement('a');
      link.href = downloadLink;
      link.download = `Campaign ${this.campaign?.campaignId} QR Code`;
      link.target = '_blank';
      link.click();
      // Clean up the temporary URL
      window.URL.revokeObjectURL(downloadLink);
    }
  }

  /**
   * @description
   * Get campaign details based on known campaignId.
   * This is used to pre-populate the form
   */
  getCampaign() {
    this.showLoading();

    const { campaignId } = this;
    if (campaignId) {
      this.campaignService.getCampaign(campaignId).subscribe({
        next: (res) => {
          this.hideLoading();
          if (res) {
            this.campaign = res;
            this.setFormData();
          }
        },
        error: () => {
          this.hideLoading();
        },
      });
    }
  }

  /**
   * Gets the campaigns QR Code and sets it
   */
  getCampaignQrCode() {
    const { campaignId } = this;
    if (campaignId) {
      this.campaignService.getCampaignQrCode(campaignId).subscribe({
        next: (resp) => {
          this.qrCode = resp;
        },
        error: () => {
          this.qrCode = undefined;
        },
      });
    }
  }

  save() {
    if (!this.form.valid) {
      return;
    }

    // Show loading
    this.showLoading();

    // Reset errors;
    this.error = undefined;

    // Convert the form data for submission
    const campaign: Campaign = new Campaign(this.form.getRawValue());

    /**
     * Determine if this is a new campaign or update based on the presence of a campaignId
     */
    if (this.campaignId) {
      if (campaign.defaultLocale == 'undefined') {
        delete campaign.defaultLocale;
      }

      this.campaignService.updateCampaign(campaign).subscribe({
        next: (res) => {
          this.hideLoading();

          if (res) {
            this.campaignService.updateSortDirection('desc');
            this.campaignService.updateSortField('updatedDate');

            this.router.navigateByUrl('/campaigns', {
              state: {
                alertType: AlertType.SuccessUpdate,
                alertText: 'CAMPAIGN.ALERT_UPDATE_SUCCESS',
              },
            });
          }
        },
        error: (err) => {
          this.hideLoading();
          this.error = err.error;
          this.setErrorsOnFormFields();
        },
      });
    } else {
      this.campaignService.createCampaign(campaign).subscribe({
        next: (res) => {
          this.hideLoading();

          if (res) {
            this.campaignService.updateSortDirection('desc');
            this.campaignService.updateSortField('createdDate');

            this.router.navigateByUrl('/campaigns', {
              state: {
                alertType: AlertType.SuccessCreate,
                alertText: 'CAMPAIGN.ALERT_CREATE_SUCCESS',
              },
            });
          }
        },
        error: (err) => {
          this.hideLoading();
          this.error = err.error;
          this.setErrorsOnFormFields();
        },
      });
    }
  }

  isFieldInvalid(field: string) {
    const control = this.form.get(field);
    return control?.invalid && (control.dirty || control.touched);
  }

  getErrorMessage(field: string) {
    const control = this.form.get(field);
    if (control?.errors) {
      const errors = control.errors;
      const capitalizedField = field.charAt(0).toUpperCase() + field.slice(1);
      if (errors['required']) {
        return `${capitalizedField} is required`;
      }
      if (errors['pattern']) {
        if (field === 'campaignId') {
          return `${capitalizedField} must be alpha numeric and lower case`;
        }
        return 'Please enter a valid format';
      }
      // Handle other error cases here
    }
    return '';
  }

  setErrorsOnFormFields() {
    if (this.error) {
      this.error.details?.forEach((error) => {
        const control = this.form.get(error.name);
        if (control) {
          control.setErrors({ backendError: error.reason });
          control.markAsTouched();
        }
      });
    }
  }

  /**
   * Emitted when a service item is clicked on from the list
   * @param event
   * @returns
   */
  serviceValueChanged(event: any) {
    const newVal = event.target.value;
    const currentVal = this.form.get('selectedServiceCode')?.value;
    if (currentVal === newVal) {
      // clear if deselecting
      this.form.get('selectedServiceCode')?.setValue('');
      this.form.get('selectedVaccineCode')?.setValue('');
      this.form.get('serviceLocked')?.setValue(false);
      this.vaccines = undefined;
    } else {
      this.form.get('selectedServiceCode')?.setValue(newVal);
      this.form.get('selectedVaccineCode')?.setValue('');
      this.setVaccines();
    }
  }

  /**
   * Sets the form controls with their default values and state
   */
  setFormData() {
    // To disable any of these fields use the isReadOnly boolean
    // isReadOnly is set if a campaignId exists (Edit mode)
    this.form = new FormGroup({
      campaignId: new FormControl(
        {
          value: this.campaign?.campaignId ?? undefined,
          disabled: this.isReadOnly,
        },
        [Validators.pattern(/^[a-z0-9]+$/)]
      ),
      name: new FormControl(
        {
          value: this.campaign?.name ?? undefined,
          disabled: false,
        },
        [Validators.required]
      ),
      defaultLocale: new FormControl({
        value: this.campaign?.defaultLocale ?? undefined,
        disabled: false,
      }),
      defaultZipCode: new FormControl({
        value: this.campaign?.defaultZipCode ?? undefined,
        disabled: false,
      }),
      locationGroupId: new FormControl({
        value: this.campaign?.locationGroupId ?? undefined,
        disabled: false,
      }),
      locationGroupParentId: new FormControl({
        value: this.campaign?.locationGroupParentId ?? undefined,
        disabled: false,
      }),
      selectedServiceCode: new FormControl({
        value: this.campaign?.selectedServiceCode ?? undefined,
        disabled: false,
      }),
      selectedVaccineCode: new FormControl({
        value: this.campaign?.selectedVaccineCode ?? undefined,
        disabled: false,
      }),
      serviceLocked: new FormControl({
        value: this.campaign?.serviceLocked ?? false,
        disabled: false,
      }),
      serviceOverrideList: new FormControl({
        value: this.campaign?.serviceOverrideList ?? 'services',
        disabled: false,
      }),
      schedulingWindowDays: new FormControl({
        value: this.campaign?.schedulingWindowDays ?? undefined,
        disabled: false,
      }),
      disabled: new FormControl(this.campaign?.disabled ?? false),
      theme: this.setFormThemeData(),
    });
  }

  setFormThemeData(): FormGroup {
    return new FormGroup({
      tintColor: new FormControl(
        {
          value: this.campaign?.theme?.tintColor ?? DEFAULT_THEME.tintColor,
          disabled: false,
        },
        [Validators.required]
      ),
      tintColorDark: new FormControl(
        {
          value:
            this.campaign?.theme?.tintColorDark ?? DEFAULT_THEME.tintColorDark,
          disabled: false,
        },
        [Validators.required]
      ),
      tintActiveColor: new FormControl(
        {
          value:
            this.campaign?.theme?.tintActiveColor ??
            DEFAULT_THEME.tintActiveColor,
          disabled: false,
        },
        [Validators.required]
      ),
      tintActiveColorDark: new FormControl(
        {
          value:
            this.campaign?.theme?.tintActiveColorDark ??
            DEFAULT_THEME.tintActiveColorDark,
          disabled: false,
        },
        [Validators.required]
      ),
      backgroundColor: new FormControl(
        {
          value:
            this.campaign?.theme?.backgroundColor ??
            DEFAULT_THEME.backgroundColor,
          disabled: false,
        },
        [Validators.required]
      ),
      backgroundColorDark: new FormControl(
        {
          value:
            this.campaign?.theme?.backgroundColorDark ??
            DEFAULT_THEME.backgroundColorDark,
          disabled: false,
        },
        [Validators.required]
      ),
      textColor: new FormControl(
        {
          value: this.campaign?.theme?.textColor ?? DEFAULT_THEME.textColor,
          disabled: false,
        },
        [Validators.required]
      ),
      textColorDark: new FormControl(
        {
          value:
            this.campaign?.theme?.textColorDark ?? DEFAULT_THEME.textColorDark,
          disabled: false,
        },
        [Validators.required]
      ),
    });
  }

  setLocationGroupParents() {
    this.showLoading();

    // clear existing
    this.locationGroupParents = [];

    this.locationGroupService.getParentLocationGroups().subscribe({
      next: (res: LocationGroupParent[]) => {
        this.locationGroupParents = res;
        this.hideLoading();
      },
      error: () => {
        this.locationGroupParents = undefined;
        this.hideLoading();
      },
    });
  }

  /**
   * Sets the list of location groups that are available to choose from for the Associated Pharmacy Group
   */
  setLocationGroups() {
    const requestBody: any = {
      startRow: 0,
      endRow: 9999999,
      maxRows: 9999999,
    };

    this.locationGroupService.getLocationGroups(requestBody).subscribe({
      next: (res) => {
        this.locationGroups = res;
      },
      error: () => {
        this.locationGroups = undefined;
      },
    });
  }

  /**
   * Sets the services list that can be used to then get the list of services for the specific list
   */
  setServicesList() {
    this.showLoading();

    // clear existing
    this.servicesList = [];

    this.campaignService.getServicesList().subscribe({
      next: (res: ServicesList[]) => {
        this.servicesList = res;

        setTimeout(() => {
          this.setServices();
        }, 100);

        this.hideLoading();
      },
      error: () => {
        this.servicesList = undefined;

        setTimeout(() => {
          this.setServices();
        }, 100);

        this.hideLoading();
      },
    });
  }

  /**
   * Sets the list of services that are available to choose from
   */
  setServices() {
    this.showLoading();

    // Clear existing
    this.serviceGroups = [];
    this.vaccines = [];

    const serviceListId = this.form.get('serviceOverrideList')?.value;
    this.campaignService.getServices(serviceListId).subscribe({
      next: (res) => {
        this.serviceGroups = res;

        setTimeout(() => {
          this.setVaccines();
        }, 100);

        this.hideLoading();
      },
      error: () => {
        this.serviceGroups = undefined;

        setTimeout(() => {
          this.setVaccines();
        }, 100);

        this.hideLoading();
      },
    });
  }

  /**
   * Called when the selectedServiceCode is set
   */
  setVaccines() {
    const serviceCodeValue = this.form.get('selectedServiceCode')?.value;
    if (serviceCodeValue) {
      this.serviceGroups?.forEach((serviceGroup) => {
        const selectedService = serviceGroup.services.find(
          (v) => v.serviceCode == serviceCodeValue
        );
        if (selectedService) {
          this.vaccines = selectedService?.vaccines;
        }
      });

      // Pre-select the first vaccineCode if only 1 exists
      if (this.vaccines && this.vaccines.length === 1) {
        const { vaccineCode } = this.vaccines[0];
        this.form.get('selectedVaccineCode')?.setValue(vaccineCode ?? '');
      }
    }
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }

  /**
   * Emitted when a vaccine item is clicked on from the list
   * @param event
   * @returns
   */
  vaccineValueChanged(event: any) {
    const newVal = event.target.value;
    const currentVal = this.form.get('selectedVaccineCode')?.value;
    if (currentVal === newVal) {
      // clear if deselecting
      this.form.get('selectedVaccineCode')?.setValue('');
    } else {
      this.form.get('selectedVaccineCode')?.setValue(newVal);
    }
  }

  servicesListValueChanged(event: any) {
    const newVal = event.target.value;
    const currentVal = this.form.get('serviceOverrideList')?.value;
    if (currentVal === newVal) {
      // already set
      this.setServices();
    } else {
      this.form.get('selectedServiceCode')?.setValue('');
      this.form.get('selectedVaccineCode')?.setValue('');
      this.setServices();
    }
  }

  // Need to clear the locationGroupParentId if a locationGroupId is set
  // only one or the other can be set at a time
  locationGroupValueChanged(event: any) {
    const newVal = event.target.value;
    if (newVal) {
      this.form.get('locationGroupParentId')?.setValue(undefined);
    }
  }

  // Need to clear the locationGroupId if a locationGroupParentId is set
  // only one or the other can be set at a time
  locationGroupParentValueChanged(event: any) {
    const newVal = event.target.value;
    if (newVal) {
      this.form.get('locationGroupId')?.setValue(undefined);
    }
  }
}
