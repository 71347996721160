import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DEFAULT_THEME } from 'src/app/common/constants/general';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-logo-example',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './logo-example.component.html',
  styleUrls: ['./logo-example.component.scss'],
})
export class LogoExampleComponent {
  @Input() logoUrl? = '';
  backgroundColor = DEFAULT_THEME.backgroundColor;
  textColor = DEFAULT_THEME.textColor;
}
