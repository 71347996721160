import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  apiBaseUrl = environment.apiBaseUrl;
  apiKey = environment.apiKey;
  onrampVersion = environment.version;

  isNavDrawerOpenObj = new BehaviorSubject(false);
  isNavDrawerOpen$: Observable<boolean>;

  set isNavDrawerOpen(isOpen: boolean) {
    this.isNavDrawerOpenObj.next(isOpen);
  }

  get isNavDrawerOpen() {
    return this.isNavDrawerOpenObj.value;
  }

  constructor() {
    this.isNavDrawerOpen$ = this.isNavDrawerOpenObj.asObservable();
  }
}
