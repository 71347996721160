<main class="container-fluid p-0">
  <div id="location-group-container" class="py-4 px-3 px-md-5">
    <header class="mb-4 d-flex justify-content-between">
      <h3 class="text-primary">
        {{
          locationGroupId
            ? ('LOCATION_GROUP.TITLE_EDIT' | translate)
            : ('LOCATION_GROUP.TITLE_CREATE' | translate)
        }}
      </h3>

      <!-- STATUS -->
      <div id="status-container" class="d-flex flex-row align-self-center">
        <label
          [innerText]="'LOCATION_GROUP.FORM.FIELD.STATUS' | translate"
          class="text-secondary text-capitalize me-2 text-bold"
          for="status"
        ></label>
        <div class="form-check form-switch">
          <span
            [class.active]="form.get('disabled')?.value === false"
            [class.inactive]="form.get('disabled')?.value === true"
          >
            {{
              form.get('disabled')?.value
                ? ('LOCATION_GROUP.FORM.FIELD.STATUS_FALSE' | translate)
                : ('LOCATION_GROUP.FORM.FIELD.STATUS_TRUE' | translate)
            }}
          </span>
          <input
            class="form-check-input toggle text-primary text-semibold"
            role="switch"
            type="checkbox"
            id="status"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="status"
            aria-label="Pharmacy Group Status"
            autocomplete="off"
          />
        </div>
      </div>
    </header>

    <form
      role="form"
      id="location-group-form"
      aria-label="Pharmacy Group Form"
      class="row"
      [formGroup]="form"
      (ngSubmit)="save()"
    >
      <div class="col-sm-12 col-lg-4">
        <accordion>
          <!-- 
          Setup 
        -->
          <accordion-group panelClass="accordion-panel" #section1 [isOpen]="true">
            <button
              class="btn btn-link btn-block justify-content-between align-items-center d-flex w-100 shadow-none"
              accordion-heading
              type="button"
            >
              {{ 'LOCATION_GROUP.SECTION_1_TITLE' | translate }}

              <svg
                class="icon"
                [class.open]="section1.isOpen"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M16.293 9.29303L12 13.586L7.70697 9.29303L6.29297 10.707L12 16.414L17.707 10.707L16.293 9.29303Z"
                  fill="currentColor"
                />
              </svg>
            </button>

            <!-- LOCATION GROUP ID -->
            <label
              class="text-secondary text-capitalize mb-1 text-bold"
              for="location-group-id"
              [innerText]="'LOCATION_GROUP.FORM.FIELD.LOCATION_GROUP_ID' | translate"
            ></label>

            <p
              class="text-tertiary"
              [innerText]="'LOCATION_GROUP.FORM.FIELD.LOCATION_GROUP_ID_DESCRIPTION' | translate"
            ></p>

            <input
              type="text"
              id="location-group-id"
              placeholder="Available after pharmacy group creation"
              [class.form-field-error]="isFieldInvalid('locationGroupId')"
              formControlName="locationGroupId"
              class="form-control text-primary mb-2 text-semibold"
            />

            <div
              *ngIf="isFieldInvalid('locationGroupId')"
              class="text-danger d-flex flex-column mb-2"
            >
              <span>{{ getErrorMessage('locationGroupId') }}</span>
              <span *ngIf="form.get('locationGroupId')?.errors?.['backendError']">
                {{ form.get('locationGroupId')?.errors?.['backendError'] }}
              </span>
            </div>

            <!-- NAME -->
            <label class="text-secondary text-capitalize mt-3 mb-1 text-bold" for="name">
              {{ 'LOCATION_GROUP.FORM.FIELD.NAME' | translate }}
              <span class="text-danger">*</span>
            </label>
            <p
              class="text-tertiary"
              [innerText]="'LOCATION_GROUP.FORM.FIELD.NAME_DESCRIPTION' | translate"
            ></p>

            <div class="position-relative">
              <input
                type="text"
                id="name"
                [class.form-field-error]="isFieldInvalid('name')"
                formControlName="name"
                class="form-control text-primary mb-2 text-semibold"
              />

              <div *ngIf="isFieldInvalid('name')" class="text-danger d-flex flex-column mb-2">
                <img class="form-field-error-icon" src="assets/icons/error.svg" alt="Error icon" />
                <span>{{ getErrorMessage('name') }}</span>
                <span *ngIf="form.get('name')?.errors?.['backendError']">
                  {{ form.get('name')?.errors?.['backendError'] }}
                </span>
              </div>
            </div>

            <!-- INFORMATIONAL URL -->
            <label
              class="text-secondary text-capitalize mt-3 mb-1 text-bold"
              for="informational-url"
              [innerText]="'LOCATION_GROUP.FORM.FIELD.INFORMATIONAL_URL' | translate"
            ></label>

            <p
              class="text-tertiary"
              [innerText]="'LOCATION_GROUP.FORM.FIELD.INFORMATIONAL_URL_DESCRIPTION' | translate"
            ></p>

            <div class="position-relative">
              <input
                type="text"
                id="informational-url"
                [class.form-field-error]="isFieldInvalid('informationalUrl')"
                [placeholder]="
                  'LOCATION_GROUP.FORM.FIELD.INFORMATIONAL_URL_PLACEHOLDER' | translate
                "
                formControlName="informationalUrl"
                class="form-control text-primary mb-2 text-semibold"
              />

              <div
                *ngIf="isFieldInvalid('informationalUrl')"
                class="text-danger d-flex flex-column mb-2"
              >
                <img class="form-field-error-icon" src="assets/icons/error.svg" alt="Error icon" />
                <span>{{ getErrorMessage('informationalUrl') }}</span>
                <span *ngIf="form.get('informationalUrl')?.errors?.['backendError']">
                  {{ form.get('informationalUrl')?.errors?.['backendError'] }}
                </span>
              </div>
            </div>

            <!-- SUPPORTS SCHEDULING -->
            <label
              [innerText]="'LOCATION_GROUP.FORM.FIELD.SUPPORTS_SCHEDULING' | translate"
              class="text-secondary text-capitalize mt-3 mb-2 text-bold"
              for="supports-scheduling"
            ></label>
            <p
              class="text-tertiary"
              [innerText]="'LOCATION_GROUP.FORM.FIELD.SUPPORTS_SCHEDULING_DESCRIPTION' | translate"
            ></p>

            <div class="form-check form-switch">
              {{
                form.get('supportsScheduling')?.value
                  ? ('LOCATION_GROUP.FORM.FIELD.SUPPORTS_SCHEDULING_TRUE' | translate)
                  : ('LOCATION_GROUP.FORM.FIELD.SUPPORTS_SCHEDULING_FALSE' | translate)
              }}
              <input
                class="form-check-input toggle text-primary text-semibold"
                role="switch"
                type="checkbox"
                id="status"
                formControlName="supportsScheduling"
                aria-label="Supports Scheduling"
                autocomplete="off"
              />
            </div>

            <!-- BOOKING URL -->
            <label
              class="text-secondary text-capitalize mt-3 mb-1 text-bold"
              for="booking-url"
              [innerText]="'LOCATION_GROUP.FORM.FIELD.BOOKING_URL' | translate"
            ></label>
            <p
              class="text-tertiary"
              [innerText]="'LOCATION_GROUP.FORM.FIELD.BOOKING_URL_DESCRIPTION' | translate"
            ></p>

            <div class="position-relative">
              <input
                type="text"
                id="booking-url"
                [class.form-field-error]="isFieldInvalid('bookingUrl')"
                formControlName="bookingUrl"
                [placeholder]="'LOCATION_GROUP.FORM.FIELD.BOOKING_URL_PLACEHOLDER' | translate"
                class="form-control text-primary mb-2 text-semibold"
              />

              <div *ngIf="isFieldInvalid('bookingUrl')" class="text-danger d-flex flex-column mb-2">
                <img class="form-field-error-icon" src="assets/icons/error.svg" alt="Error icon" />
                <span>{{ getErrorMessage('bookingUrl') }}</span>
                <span *ngIf="form.get('bookingUrl')?.errors?.['backendError']">
                  {{ form.get('bookingUrl')?.errors?.['backendError'] }}
                </span>
              </div>
            </div>
          </accordion-group>
        </accordion>
      </div>

      <div class="col-sm-12 col-lg-8">
        <accordion>
          <accordion-group panelClass="accordion-panel" #section2>
            <button
              class="btn btn-link btn-block justify-content-between align-items-center d-flex w-100 shadow-none"
              accordion-heading
              type="button"
            >
              {{ 'LOCATION_GROUP.SECTION_2_TITLE' | translate }}

              <svg
                class="icon"
                [class.open]="section2.isOpen"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M16.293 9.29303L12 13.586L7.70697 9.29303L6.29297 10.707L12 16.414L17.707 10.707L16.293 9.29303Z"
                  fill="currentColor"
                />
              </svg>
            </button>

            <!-- LOGO -->
            <label
              [innerText]="'LOCATION_GROUP.FORM.FIELD.LOGO_URL' | translate"
              class="text-secondary text-capitalize mb-2 text-bold"
              for="logo-url"
            ></label>
            <p
              class="text-tertiary"
              [innerText]="'LOCATION_GROUP.FORM.FIELD.LOGO_URL_DESCRIPTION' | translate"
            ></p>

            <div class="position-relative">
              <div class="mb-3">
                <ng-container *ngIf="locationGroup?.logoUrl; else logoPlaceholder">
                  <img id="logo" alt="" [src]="locationGroup?.logoUrl" />
                </ng-container>
                <ng-template #logoPlaceholder>
                  <div
                    id="logo"
                    class="logo-placeholder d-flex flex-column justify-content-center align-items-center"
                  >
                    {{ 'LOCATION_GROUP.FORM.FIELD.LOGO_PLACEHOLDER' | translate }}
                  </div>
                </ng-template>
              </div>

              <app-logo-example [logoUrl]="locationGroup?.logoUrl"></app-logo-example>
            </div>
          </accordion-group>
        </accordion>
      </div>
    </form>

    <!-- CAMPAIGN EDIT HISTORY -->
    <div *ngIf="isReadOnly" class="d-flex mt-5 flex-column align-items-end">
      <div class="text-tertiary">
        {{ 'LOCATION_GROUP.DATE_CREATED' | translate }}
        {{ dateCreated }}
      </div>
      <div class="text-tertiary">
        {{ 'LOCATION_GROUP.DATE_UPDATED' | translate }}
        {{ dateUpdated }}
      </div>
    </div>
  </div>

  <footer>
    <div class="container d-flex gap-2 py-4 px-3 justify-content-around">
      <button
        [innerText]="'LOCATION_GROUP.FORM.BUTTON_CANCEL' | translate"
        (click)="cancel()"
        class="btn btn-outline-primary text-uppercase text-semibold d-flex flex-column flex-fill align-items-center w-100 h-100 justify-content-center"
      ></button>

      <button
        [disabled]="!form.valid || loading"
        (click)="save()"
        class="btn btn-primary text-uppercase text-semibold d-flex flex-column flex-fill align-items-center w-100 h-100 justify-content-center"
      >
        <div class="spinner-border" role="status" *ngIf="loading">
          <span class="visually-hidden">Loading...</span>
        </div>
        {{ 'LOCATION_GROUP.FORM.BUTTON_SAVE' | translate }}
      </button>
    </div>
  </footer>
</main>

<app-navigation></app-navigation>
