import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-status-cell',
  template: `<span [innerHTML]="status" [class]="statusClass"></span>`,
  styleUrls: ['./status-cell-renderer.component.scss'],
})
export class StatusCellRendererComponent implements ICellRendererAngularComp {
  public params!: ICellRendererParams;
  public status!: string;
  public statusClass!: string;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.setStatus(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.setStatus(params);
    return true;
  }

  private setStatus(params: ICellRendererParams) {
    this.status = params.value ? 'Inactive' : 'Active';

    switch (params.value) {
      case true:
        this.statusClass = 'inactive';
        return;
      case false:
        this.statusClass = 'active';
        return;
      default:
        return;
    }
  }
}
