import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Campaign } from '../../model/campaign';
import { BehaviorSubject, Observable, retry, switchMap } from 'rxjs';
import { EnvironmentService } from '../environment/environment.service';
import { API_RETRY_LIMIT } from '../../constants/general';
import { DEFAULT_GRID_OPTIONS } from '../../constants/ag-grid';
import { ServiceGroup, ServicesList } from '../../model/service';
import { SortDirection } from 'ag-grid-community';

@Injectable({
  providedIn: 'root',
})
export class CampaignService {
  searchTerm$: Observable<string>;
  sortField$: Observable<string>;
  sortDirection$: Observable<SortDirection>;
  get sortField() {
    return this._sortField.getValue();
  }
  get sortDirection() {
    return this._sortDirection.getValue();
  }
  private _searchTerm: BehaviorSubject<string> = new BehaviorSubject('');
  private _sortField: BehaviorSubject<string> = new BehaviorSubject('name');
  private _sortDirection: BehaviorSubject<SortDirection> = new BehaviorSubject(
    'asc' as SortDirection
  );
  private apiBaseUrl: string;

  constructor(private http: HttpClient, private environmentService: EnvironmentService) {
    this.apiBaseUrl = this.environmentService.apiBaseUrl;
    this.searchTerm$ = this._searchTerm.asObservable();
    this.sortField$ = this._sortField.asObservable();
    this.sortDirection$ = this._sortDirection.asObservable();
  }

  createCampaign(campaign: Campaign): Observable<Campaign> {
    const url = `${this.apiBaseUrl}/campaigns`;
    return this.http.post<Campaign>(url, campaign);
  }

  /**
   * Retrieve a campaign's details
   * @param campaignId
   * @returns
   */
  getCampaign(campaignId: string): Observable<Campaign> {
    const url = `${this.apiBaseUrl}/campaigns/${campaignId}`;
    return this.http.get<Campaign>(url).pipe(retry(API_RETRY_LIMIT));
  }

  /**
   * Get the QR code for a specific campaign
   * @param campaignId
   * @returns QR code png
   */
  getCampaignQrCode(campaignId: string): Observable<any> {
    const url = `${this.apiBaseUrl}/campaigns/${campaignId}/qr`;
    return this.http.get(url, { responseType: 'blob' }).pipe(
      retry(API_RETRY_LIMIT),
      switchMap((response: Blob) => {
        return this.convertToBase64(response);
      })
    );
  }

  convertToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  }

  /**
   * Search for Campaigns
   * @param params
   * @returns The list of campaigns
   */
  getCampaigns(params: any): Observable<Campaign[]> {
    const request = {
      sortField: params.sortField ?? 'campaignId',
      sortDirection: params.sortDirection ?? 'asc',
      startRow: params.startRow ?? 0,
      maxRows: params.maxRows ?? DEFAULT_GRID_OPTIONS.CACHE_BLOCK_SIZE,
      searchTerm: params.searchTerm ?? '',
    };
    const url = `${this.apiBaseUrl}/campaigns/search`;
    return this.http.post<Campaign[]>(url, request).pipe(retry(API_RETRY_LIMIT));
  }

  /**
   * Gets the list of available service lists
   * @returns ServicesList[]
   */
  getServicesList(): Observable<ServicesList[]> {
    const url = `${this.apiBaseUrl}/services/list`;
    return this.http.get<ServicesList[]>(url).pipe(retry(API_RETRY_LIMIT));
  }

  /**
   *
   * @returns The list of available services and their associated vaccines
   */
  getServices(serviceListId?: string): Observable<ServiceGroup[]> {
    const url = `${this.apiBaseUrl}/services?id=${serviceListId}`;
    return this.http.get<ServiceGroup[]>(url).pipe(retry(API_RETRY_LIMIT));
  }

  /**
   * Updates an existing campaign
   * @param campaign
   * @returns
   */
  updateCampaign(campaign: Campaign): Observable<Campaign> {
    const url = `${this.apiBaseUrl}/campaigns/${campaign.campaignId}`;
    return this.http.put<Campaign>(url, campaign);
  }

  updateSortField(field: string) {
    this._sortField.next(field);
  }

  updateSortDirection(direction: SortDirection) {
    this._sortDirection.next(direction);
  }

  updateSearchTerm(term: string) {
    this._searchTerm.next(term);
  }
}
