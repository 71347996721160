import { Routes } from '@angular/router';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { CampaignComponent } from './campaign/campaign.component';
import { LocationGroupsComponent } from './location-groups/location-groups.component';
import { LocationGroupComponent } from './location-group/location-group.component';
import { MsalGuard } from '@azure/msal-angular';

export const ROUTES: Routes = [
  {
    path: 'campaigns',
    title: 'EasyVax Scheduler Configuration | Campaigns',
    canActivate: [MsalGuard],
    component: CampaignsComponent,
  },
  {
    path: 'campaigns/:campaignId',
    title: 'EasyVax Scheduler Configuration | Campaign',
    canActivate: [MsalGuard],
    component: CampaignComponent,
  },
  {
    path: 'pharmacy-groups',
    title: 'EasyVax Scheduler Configuration | Pharmacy Groups',
    canActivate: [MsalGuard],
    component: LocationGroupsComponent,
  },
  {
    path: 'pharmacy-groups/:locationGroupId',
    title: 'EasyVax Scheduler Configuration | Pharmacy Group',
    canActivate: [MsalGuard],
    component: LocationGroupComponent,
  },
  { path: '**', redirectTo: '/campaigns' },
];
