<div
  (mouseenter)="isExampleButtonHovered = true"
  (mouseleave)="isExampleButtonHovered = false"
  [style.border-color]="isExampleButtonHovered ? tintActiveColor : tintColor"
  [style.background-color]="isExampleButtonHovered ? tintActiveColor : tintColor"
  class="btn d-flex flex-column align-items-center time-button btn-primary text-uppercase"
>
  <span>Today</span>
  <span>{{ time }}</span>
</div>
