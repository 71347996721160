import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';
import { ILoadingOverlayParams } from 'ag-grid-community';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-grid-overlay',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './grid-overlay.component.html',
  styleUrls: ['./grid-overlay.component.scss'],
})
export class GridOverlayComponent implements ILoadingOverlayAngularComp {
  public params!: ILoadingOverlayParams & { content: string };

  agInit(params: ILoadingOverlayParams & { content: string }): void {
    this.params = params;
  }
}
