import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ThemeExampleButtonComponent } from './theme-example-button/theme-example-button.component';

@Component({
  selector: 'app-theme-example',
  standalone: true,
  imports: [CommonModule, TranslateModule, ThemeExampleButtonComponent],
  templateUrl: './theme-example.component.html',
  styleUrls: ['./theme-example.component.scss'],
})
export class ThemeExampleComponent {
  @Input() backgroundColor = '';
  @Input() textColor = '';
  @Input() tintColor = '';
  @Input() tintActiveColor = '';
}
