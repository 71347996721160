import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertType } from 'src/app/common/constants/alert';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-alert-banner',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './alert-banner.component.html',
  styleUrls: ['./alert-banner.component.scss'],
})
export class AlertBannerComponent implements OnInit, OnDestroy {
  @Input({ required: true }) alertType = AlertType.SuccessCreate;
  @Input({ required: true }) alertText = '';
  @Output() dismissAlert = new EventEmitter<void>();
  alertElement!: HTMLElement | null;
  alertIconPath = '';
  alertClass = '';

  configureAlert() {
    switch (this.alertType) {
      case AlertType.SuccessCreate:
      case AlertType.SuccessUpdate:
        this.alertClass = 'alert-success';
        this.alertIconPath = 'assets/icons/check-circle.svg';
        break;
      case AlertType.ErrorCreate:
      case AlertType.ErrorUpdate:
        this.alertClass = 'alert-danger';
        this.alertIconPath = 'assets/icons/error-circle.svg';
        break;
      default:
        break;
    }
  }

  dismiss() {
    this.dismissAlert.emit();
  }

  ngOnInit(): void {
    this.configureAlert();

    setTimeout(() => {
      this.alertElement = document.getElementById('alert');
      if (this.alertElement) {
        this.alertElement.addEventListener('closed.bs.alert', () => {
          this.dismiss();
        });
      }
    }, 100);
  }

  ngOnDestroy(): void {
    if (this.alertElement) {
      this.alertElement.removeEventListener('closed.bs.alert', () => {
        this.dismiss();
      });
    }
  }
}
