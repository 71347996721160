import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class EnvironmentGuard {
  constructor(private router: Router) {}

  canActivate(): boolean {
    // TODO: update once we have login
    return true;
  }
}
