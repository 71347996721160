import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import { Router } from '@angular/router';
import { LocationGroupService } from '../common/services/location-group/location-group.service';
import {
  ColDef,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  IDatasource,
  IGetRowsParams,
  SortDirection,
} from 'ag-grid-community';
import { DEFAULT_GRID_OPTIONS } from '../common/constants/ag-grid';
import { NavigationComponent } from '../navigation/navigation.component';
import { StatusCellRendererComponent } from '../components/grid/status-cell-renderer/status-cell-renderer.component';
import { GridOverlayComponent } from '../components/grid/grid-overlay/grid-overlay.component';
import { AlertType } from '../common/constants/alert';
import { VaxAlert } from '../common/model/alert';
import { AlertBannerComponent } from '../components/alert-banner/alert-banner.component';
import { DateTime } from 'luxon';
import { FormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-location-groups',
  standalone: true,
  imports: [
    CommonModule,
    AgGridModule,
    TranslateModule,
    FormsModule,
    NavigationComponent,
    AlertBannerComponent,
  ],
  templateUrl: './location-groups.component.html',
  styleUrls: ['./location-groups.component.scss'],
})
export class LocationGroupsComponent implements OnInit, OnDestroy {
  /**
   * Default Column properties used for the Location Groups grid
   * These can be overridden per column in the gridColumnDefs
   */
  defaultColDef: ColDef = {
    sortable: true,
    resizable: true,
    minWidth: 177,
    flex: 1,
    sortingOrder: ['asc', 'desc'],
  };

  /**
   * GridOptions used for the Location Groups grid configuration
   */
  gridOptions: GridOptions = {
    noRowsOverlayComponent: GridOverlayComponent,
    noRowsOverlayComponentParams: { content: 'LOCATION_GROUPS.GRID.NO_ROWS' },
    loadingOverlayComponent: GridOverlayComponent,
    loadingOverlayComponentParams: { content: 'LOCATION_GROUPS.GRID.LOADING' },
    suppressNoRowsOverlay: false,
    defaultColDef: this.defaultColDef,
    rowData: DEFAULT_GRID_OPTIONS.ROW_DATA,
    accentedSort: DEFAULT_GRID_OPTIONS.ACCENTED_SORT,
    rowSelection: DEFAULT_GRID_OPTIONS.ROW_SELECTION,
    rowModelType: DEFAULT_GRID_OPTIONS.ROW_MODEL_TYPE,
    rowBuffer: DEFAULT_GRID_OPTIONS.ROW_BUFFER,
    cacheBlockSize: DEFAULT_GRID_OPTIONS.CACHE_BLOCK_SIZE,
    cacheOverflowSize: DEFAULT_GRID_OPTIONS.CACHE_OVERFLOW_SIZE,
    maxConcurrentDatasourceRequests: DEFAULT_GRID_OPTIONS.MAX_CONCURRENT_DATASOURCE_REQUESTS,
    infiniteInitialRowCount: DEFAULT_GRID_OPTIONS.INFINITE_INITIAL_ROW_COUNT,
    maxBlocksInCache: DEFAULT_GRID_OPTIONS.MAX_BLOCKS_IN_CACHE,
    suppressMultiSort: DEFAULT_GRID_OPTIONS.SUPPRESS_MULTI_SORT,
    columnApi: DEFAULT_GRID_OPTIONS.COLUMN_API,
    api: DEFAULT_GRID_OPTIONS.API,
  };
  error?: string;
  searchTerm = '';
  sortField = 'name';
  sortDirection: SortDirection = 'asc';
  subscriptions = new Subscription();
  loading = false;
  alert: VaxAlert = {
    text: '',
    type: AlertType.SuccessCreate,
    visible: false,
  };

  constructor(
    private router: Router,
    private locationGroupService: LocationGroupService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    // Set up subscriptions
    const sortFieldSub = this.locationGroupService.sortField$.subscribe((field) => {
      this.sortField = field;
    });
    this.subscriptions.add(sortFieldSub);

    const sortDirectionSub = this.locationGroupService.sortDirection$.subscribe((direction) => {
      this.sortDirection = direction;
    });
    this.subscriptions.add(sortDirectionSub);

    const searchTermSub = this.locationGroupService.searchTerm$.subscribe((term) => {
      this.searchTerm = term;
    });
    this.subscriptions.add(searchTermSub);

    // Handle state for column sort updates
    // Check for state and previousNavigation - previousNavigation means we came from campaign create
    // this resolves an issue with refreshing after navigating on successful campaign create.
    // Previously refreshing would retain the sortField from the router state.
    // If desired to retain remove the check for previousNavigation
    const state = this.router.getCurrentNavigation()?.extras.state;
    const previousNavigation = this.router.getCurrentNavigation()?.previousNavigation;
    if (state && previousNavigation) {
      if (state['alertType']) {
        const alertType = state['alertType'];
        const alertText = state['alertText'];
        this.alert = {
          type: alertType,
          text: alertText,
          visible: true,
        };
      }

      const { sortField, sortDirection } = this;
      this.updateColumnSortState(sortField, sortDirection);
    }

    this.setGridColumnDefs();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async add() {
    await this.router.navigateByUrl('/pharmacy-groups/create');
  }

  dismissAlert() {
    this.alert = {
      text: '',
      type: AlertType.SuccessCreate,
      visible: false,
    };
  }

  getNextDataSet(params: IGetRowsParams) {
    this.showLoading();
    this.gridOptions?.api?.showLoadingOverlay();

    const requestBody: any = {
      startRow: params.startRow,
      endRow: params.endRow,
      maxRows: this.gridOptions.cacheBlockSize,
      searchTerm: this.searchTerm,
    };

    if (params?.sortModel?.length > 0) {
      // set the sortField in location group service - this means they sorted by column
      this.locationGroupService.updateSortField(params.sortModel[0].colId);
      this.locationGroupService.updateSortDirection(params.sortModel[0].sort);

      requestBody.sortDirection = params.sortModel[0].sort;
      requestBody.sortField = params.sortModel[0].colId;
    } else {
      const { sortField, sortDirection } = this;

      requestBody.sortDirection = sortDirection;
      requestBody.sortField = sortField;
    }

    this.locationGroupService.getLocationGroups(requestBody).subscribe({
      next: (data) => {
        let lastRow = -1;
        // if the length of the data is less than our cacheBlockSize it means we're on the last page
        // and we need to work out the lastRow
        if (
          data.length < (this.gridOptions.cacheBlockSize ?? DEFAULT_GRID_OPTIONS.CACHE_BLOCK_SIZE)
        ) {
          lastRow = params.startRow + data.length;
        }
        // call the success callback
        params.successCallback(data, lastRow);
        this.gridOptions?.api?.hideOverlay();
        this.hideLoading();
      },
      error: (err) => {
        params.failCallback();
        this.gridOptions.api?.setRowCount(-1); // Need this otherwise an empty row displays
        this.gridOptions?.api?.showNoRowsOverlay(); // Show the "No Rows" overlay
        this.error = err.message;
        this.hideLoading();
      },
    });
  }

  async onCellKeyDown(e: any) {
    const event = e.event as KeyboardEvent;
    if (event?.key === 'Enter') {
      await this.onRowClick(e);
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridOptions.api = params.api;
    this.gridOptions.columnApi = params.columnApi;

    const dataSource: IDatasource = {
      getRows: (params: IGetRowsParams) => {
        this.getNextDataSet(params);
      },
    };

    params.api.setDatasource(dataSource);
  }

  async onRowClick(e: any) {
    if (e.data?.locationGroupId) {
      await this.router.navigateByUrl(`/pharmacy-groups/${e.data.locationGroupId}`);
    }
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }

  searchLocationGroups() {
    this.locationGroupService.updateSearchTerm(this.searchTerm);

    // Trigger a data refresh
    this.gridOptions?.api?.onFilterChanged();
    this.gridOptions?.api?.refreshInfiniteCache();
  }

  /**
   * Set custom column properties used for the Location Groups grid
   */
  setGridColumnDefs() {
    this.gridOptions.columnDefs = [
      {
        headerName: this.translateService.instant('LOCATION_GROUPS.GRID.HEADER_NAME'),
        field: 'name',
        cellClass: 'name',
        sort: this.sortField === 'name' ? this.sortDirection : null,
      },
      {
        headerName: this.translateService.instant('LOCATION_GROUPS.GRID.HEADER_LOCATION_GROUP_ID'),
        field: 'locationGroupId',
        cellClass: 'location-group-id',
        sort: this.sortField === 'locationGroupId' ? this.sortDirection : null,
      },
      {
        headerName: this.translateService.instant(
          'LOCATION_GROUPS.GRID.HEADER_SUPPORTS_SCHEDULING'
        ),
        field: 'supportsScheduling',
        cellClass: 'supports-scheduling',
        sort: this.sortField === 'supportsScheduling' ? this.sortDirection : null,
        cellDataType: 'boolean',
        cellRenderer: function (params: any) {
          if (!params.data) {
            return;
          }
          return params.value ? 'Yes' : 'No';
        },
      },
      {
        headerName: this.translateService.instant('LOCATION_GROUPS.GRID.HEADER_BOOKING_URL'),
        field: 'bookingUrl',
        cellClass: 'booking-url',
        sort: this.sortField === 'bookingUrl' ? this.sortDirection : null,
      },
      {
        headerName: this.translateService.instant('LOCATION_GROUPS.GRID.HEADER_INFO_URL'),
        field: 'informationalUrl',
        cellClass: 'informational-url',
        sort: this.sortField === 'informationalUrl' ? this.sortDirection : null,
      },
      {
        headerName: this.translateService.instant('LOCATION_GROUPS.GRID.HEADER_STATUS'),
        field: 'disabled',
        cellClass: 'status',
        minWidth: 115,
        maxWidth: 115,
        sort: this.sortField === 'disabled' ? this.sortDirection : null,
        cellDataType: 'boolean',
        cellRendererSelector: function (params: ICellRendererParams) {
          if (!params.data) {
            return;
          }

          const statusDetails = {
            component: StatusCellRendererComponent,
          };
          return statusDetails;
        },
      },
      {
        headerName: this.translateService.instant('LOCATION_GROUPS.GRID.HEADER_DATE_CREATED'),
        field: 'createdDate',
        cellClass: 'date-created',
        sort: this.sortField === 'createdDate' ? this.sortDirection : null,
        cellRenderer: function (params: any) {
          if (!params.data || !params.value) {
            return;
          }
          return DateTime.fromISO(params.value).toFormat("MMM d, yyyy 'at' h:mm a");
        },
      },
      {
        headerName: this.translateService.instant('LOCATION_GROUPS.GRID.HEADER_DATE_UPDATED'),
        field: 'updatedDate',
        cellClass: 'date-updated',
        sort: this.sortField === 'updatedDate' ? this.sortDirection : null,
        cellRenderer: function (params: any) {
          if (!params.data || !params.value) {
            return;
          }
          return DateTime.fromISO(params.value).toFormat("MMM d, yyyy 'at' h:mm a");
        },
      },
    ];
  }

  /**
   * Update the current column sort state
   * @param column - one of the column field names
   * @param direction - asc or desc
   */
  updateColumnSortState(column: string, direction: SortDirection) {
    this.locationGroupService.updateSortField(column);
    this.locationGroupService.updateSortDirection(direction);

    this.gridOptions.columnApi?.applyColumnState({
      state: [{ colId: column, sort: direction }],
      defaultState: { sort: null },
    });

    this.gridOptions.api?.onSortChanged();
  }
}
