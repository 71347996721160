<p
  [innerText]="'LOCATION_GROUP.FORM.FIELD.LOGO_EXAMPLE' | translate"
  class="text-primary mt-3 text-bold"
></p>
<div id="theme-sample-container" class="w-100 p-1">
  <div id="theme-sample" class="p-4" [style.background-color]="backgroundColor">
    <div class="card">
      <div class="card-body">
        <div class="d-flex gap-3">
          <div>
            <ng-container *ngIf="logoUrl; else logoPlaceholder">
              <img id="theme-sample-logo" alt="Logo" [src]="logoUrl" />
            </ng-container>
            <ng-template #logoPlaceholder>
              <div
                id="theme-sample-logo"
                class="placeholder-logo d-flex flex-column justify-content-center align-items-center"
              >
                {{ 'LOCATION_GROUP.FORM.FIELD.LOGO_PLACEHOLDER' | translate }}
              </div>
            </ng-template>
          </div>
          <div>
            <h3 class="m-0" [style.color]="textColor">Pharmacy Name</h3>
            <address class="text-tertiary m-0">
              <span class="d-flex gap-3">
                123 Street Address
                <span [style.color]="textColor">1.2 mi</span>
              </span>
              <span>City, State</span>
            </address>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
