import { RowModelType } from 'ag-grid-community';

export const DEFAULT_GRID_OPTIONS = {
  ACCENTED_SORT: false,
  ROW_SELECTION: 'single' as 'single' | 'multiple',
  ROW_MODEL_TYPE: 'infinite' as RowModelType | undefined,
  ROW_BUFFER: 0,
  CACHE_BLOCK_SIZE: 100,
  CACHE_OVERFLOW_SIZE: 10,
  MAX_CONCURRENT_DATASOURCE_REQUESTS: 2,
  INFINITE_INITIAL_ROW_COUNT: 1,
  MAX_BLOCKS_IN_CACHE: 1000,
  SUPPRESS_MULTI_SORT: true,
  API: null,
  COLUMN_API: null,
  ROW_DATA: null,
  COLUMN_DEFS: null,
};
