import { Injectable } from '@angular/core';
import { LocationGroup } from '../../model/location-group';
import { BehaviorSubject, Observable, retry } from 'rxjs';
import { DEFAULT_GRID_OPTIONS } from '../../constants/ag-grid';
import { API_RETRY_LIMIT } from '../../constants/general';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '../environment/environment.service';
import { SortDirection } from 'ag-grid-community';
import { LocationGroupParent } from '../../model/location-group-parent';

@Injectable({
  providedIn: 'root',
})
export class LocationGroupService {
  searchTerm$: Observable<string>;
  sortField$: Observable<string>;
  sortDirection$: Observable<SortDirection>;
  get sortField() {
    return this._sortField.getValue();
  }
  get sortDirection() {
    return this._sortDirection.getValue();
  }
  private _searchTerm: BehaviorSubject<string> = new BehaviorSubject('');
  private _sortField: BehaviorSubject<string> = new BehaviorSubject('name');
  private _sortDirection: BehaviorSubject<SortDirection> = new BehaviorSubject(
    'asc' as SortDirection
  );
  private apiBaseUrl: string;

  constructor(private http: HttpClient, private environmentService: EnvironmentService) {
    this.apiBaseUrl = this.environmentService.apiBaseUrl;
    this.searchTerm$ = this._searchTerm.asObservable();
    this.sortField$ = this._sortField.asObservable();
    this.sortDirection$ = this._sortDirection.asObservable();
  }

  /**
   * Create a new location group
   * @param locationGroup
   * @returns
   */
  createLocationGroup(locationGroup: LocationGroup): Observable<LocationGroup> {
    const url = `${this.apiBaseUrl}/locationGroups`;
    return this.http.post<LocationGroup>(url, locationGroup);
  }

  /**
   * Retrieve a location group's detail
   * @param locationGroupId
   * @returns
   */
  getLocationGroup(locationGroupId: string): Observable<LocationGroup> {
    const url = `${this.apiBaseUrl}/locationGroups/${locationGroupId}`;
    return this.http.get<LocationGroup>(url).pipe(retry(API_RETRY_LIMIT));
  }

  /**
   * Get List of Parent Location Groups
   * @returns
   */
  getParentLocationGroups(): Observable<LocationGroupParent[]> {
    const url = `${this.apiBaseUrl}/locationGroupParents`;
    return this.http.get<LocationGroupParent[]>(url).pipe(retry(API_RETRY_LIMIT));
  }

  /**
   * Search for Location Groups
   * @param params
   * @returns The list of Location Groups
   */
  getLocationGroups(params: any): Observable<LocationGroup[]> {
    const request = {
      sortField: params.sortField ?? 'name',
      sortDirection: params.sortDirection ?? 'asc',
      startRow: params.startRow ?? 0,
      maxRows: params.maxRows ?? DEFAULT_GRID_OPTIONS.CACHE_BLOCK_SIZE,
      searchTerm: params.searchTerm ?? '',
    };
    const url = `${this.apiBaseUrl}/locationGroups/search`;
    return this.http.post<LocationGroup[]>(url, request).pipe(retry(API_RETRY_LIMIT));
  }

  /**
   * Updates an existing location group
   * @param locationGroup
   * @returns
   */
  updateLocationGroup(locationGroup: LocationGroup): Observable<LocationGroup> {
    const url = `${this.apiBaseUrl}/locationGroups/${locationGroup.locationGroupId}`;
    return this.http.put<LocationGroup>(url, locationGroup);
  }

  updateSortField(field: string) {
    this._sortField.next(field);
  }

  updateSortDirection(direction: SortDirection) {
    this._sortDirection.next(direction);
  }

  updateSearchTerm(term: string) {
    this._searchTerm.next(term);
  }
}
