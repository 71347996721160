<main class="container-fluid py-4 px-3 px-md-5">
  <header>
    <h3 class="text-primary" [innerText]="'CAMPAIGNS.TITLE' | translate"></h3>

    <app-alert-banner
      *ngIf="alert.visible"
      [alertText]="alert.text"
      [alertType]="alert.type"
      (dismissAlert)="dismissAlert()"
    ></app-alert-banner>

    <div class="my-4 d-flex justify-content-between gap-2 flex-wrap">
      <div class="input-group">
        <input
          type="search"
          class="form-control text-primary text-semibold"
          [(ngModel)]="searchTerm"
          [placeholder]="'CAMPAIGNS.SEARCH_PLACEHOLDER' | translate"
          aria-label="Search"
          aria-describedby="button-search"
          (search)="searchCampaigns()"
        />
        <button
          (click)="searchCampaigns()"
          class="btn btn-primary text-uppercase text-semibold"
          type="button"
          id="button-search"
        >
          <img src="assets/icons/search.svg" alt="Search Button" />
        </button>
      </div>

      <button
        (click)="add()"
        class="btn btn-primary gap-1 text-uppercase text-semibold d-flex flex-row flex-fill align-items-center w-100 h-100 justify-content-center"
      >
        <img src="assets/icons/plus.svg" alt="Add icon" />
        {{ 'CAMPAIGNS.ADD_BUTTON' | translate }}
      </button>
    </div>
  </header>

  <ag-grid-angular
    class="ag-theme-alpine ag-theme-vax"
    [gridOptions]="gridOptions"
    (rowClicked)="onRowClick($event)"
    (gridReady)="onGridReady($event)"
    (cellKeyDown)="onCellKeyDown($event)"
  ></ag-grid-angular>
</main>

<app-navigation></app-navigation>
