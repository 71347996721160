import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CampaignService } from 'src/app/common/services/campaign/campaign.service';

@Component({
  selector: 'app-download-cell',
  template: `
    <button (click)="downloadFile($event)" class="btn btn-clear py-0 w-100">
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 16L16 11H13V4H11V11H8L12 16Z" fill="var(--vax-primary)" />
        <path
          d="M20 18H4V11H2V18C2 19.103 2.897 20 4 20H20C21.103 20 22 19.103 22 18V11H20V18Z"
          fill="var(--vax-primary)"
        />
      </svg>
    </button>
  `,
  styleUrls: ['./download-cell-renderer.component.scss'],
})
export class DownloadCellRendererComponent implements ICellRendererAngularComp {
  public params!: ICellRendererParams;
  public fileName = '';

  constructor(private campaignService: CampaignService) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.setFileName();
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.setFileName();
    return true;
  }

  downloadFile(event: Event): void {
    event.stopPropagation(); // Prevent event propagation so clicking download doesn't route to details

    // Make API request to get the file
    this.campaignService.getCampaignQrCode(this.params.data?.campaignId).subscribe({
      next: (resp) => {
        const link = document.createElement('a');
        link.href = resp;
        link.download = this.fileName;
        link.target = '_blank';
        link.click();

        // Clean up the temporary URL
        window.URL.revokeObjectURL(resp);
      },
      error: (err: any) => {
        console.log('Error downloading QR code', err);
      },
    });
  }

  setFileName() {
    if (this.params.colDef?.field === 'qrCode') {
      this.fileName = `Campaign ${this.params.data?.campaignId} QR Code`;
    }
  }
}
