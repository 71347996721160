import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import {
  ColDef,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  IDatasource,
  IGetRowsParams,
  SortDirection,
} from 'ag-grid-community';

import { DEFAULT_GRID_OPTIONS } from '../common/constants/ag-grid';
import { Router } from '@angular/router';
import { CampaignService } from '../common/services/campaign/campaign.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NavigationComponent } from '../navigation/navigation.component';
import { GridOverlayComponent } from '../components/grid/grid-overlay/grid-overlay.component';
import { DownloadCellRendererComponent } from '../components/grid/download-cell-renderer/download-cell-renderer.component';
import { StatusCellRendererComponent } from '../components/grid/status-cell-renderer/status-cell-renderer.component';
import { AlertBannerComponent } from '../components/alert-banner/alert-banner.component';
import { AlertType } from '../common/constants/alert';
import { VaxAlert } from '../common/model/alert';
import { DateTime } from 'luxon';
import { FormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-campaigns',
  standalone: true,
  imports: [
    CommonModule,
    AgGridModule,
    TranslateModule,
    NavigationComponent,
    AlertBannerComponent,
    FormsModule,
  ],
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss'],
})
export class CampaignsComponent implements OnInit, OnDestroy {
  /**
   * Default Column properties used for the Campaigns grid
   * These can be overridden per column in the gridColumnDefs
   */
  defaultColDef: ColDef = {
    sortable: true,
    resizable: true,
    minWidth: 177,
    flex: 1,
    sortingOrder: ['asc', 'desc'],
  };

  /**
   * GridOptions used for the Campaigns grid configuration
   */
  gridOptions: GridOptions = {
    noRowsOverlayComponent: GridOverlayComponent,
    noRowsOverlayComponentParams: { content: 'CAMPAIGNS.GRID.NO_ROWS' },
    loadingOverlayComponent: GridOverlayComponent,
    loadingOverlayComponentParams: { content: 'CAMPAIGNS.GRID.LOADING' },
    suppressNoRowsOverlay: false,
    defaultColDef: this.defaultColDef,
    rowData: DEFAULT_GRID_OPTIONS.ROW_DATA,
    accentedSort: DEFAULT_GRID_OPTIONS.ACCENTED_SORT,
    rowSelection: DEFAULT_GRID_OPTIONS.ROW_SELECTION,
    rowModelType: DEFAULT_GRID_OPTIONS.ROW_MODEL_TYPE,
    rowBuffer: DEFAULT_GRID_OPTIONS.ROW_BUFFER,
    cacheBlockSize: DEFAULT_GRID_OPTIONS.CACHE_BLOCK_SIZE,
    cacheOverflowSize: DEFAULT_GRID_OPTIONS.CACHE_OVERFLOW_SIZE,
    maxConcurrentDatasourceRequests:
      DEFAULT_GRID_OPTIONS.MAX_CONCURRENT_DATASOURCE_REQUESTS,
    infiniteInitialRowCount: DEFAULT_GRID_OPTIONS.INFINITE_INITIAL_ROW_COUNT,
    maxBlocksInCache: DEFAULT_GRID_OPTIONS.MAX_BLOCKS_IN_CACHE,
    suppressMultiSort: DEFAULT_GRID_OPTIONS.SUPPRESS_MULTI_SORT,
    columnApi: DEFAULT_GRID_OPTIONS.COLUMN_API,
    api: DEFAULT_GRID_OPTIONS.API,
  };
  error?: string;
  searchTerm = '';
  sortField = 'campaignId';
  sortDirection: SortDirection = 'asc';
  subscriptions = new Subscription();
  loading = false;
  alert: VaxAlert = {
    text: '',
    type: AlertType.SuccessCreate,
    visible: false,
  };

  constructor(
    private router: Router,
    private campaignService: CampaignService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    // Set up subscriptions
    const sortFieldSub = this.campaignService.sortField$.subscribe((field) => {
      this.sortField = field;
    });
    this.subscriptions.add(sortFieldSub);

    const sortDirectionSub = this.campaignService.sortDirection$.subscribe(
      (direction) => {
        this.sortDirection = direction;
      }
    );
    this.subscriptions.add(sortDirectionSub);

    const searchTermSub = this.campaignService.searchTerm$.subscribe((term) => {
      this.searchTerm = term;
    });
    this.subscriptions.add(searchTermSub);

    // Handle state for column sort updates
    // Check for state and previousNavigation - previousNavigation means we came from campaign create
    // this resolves an issue with refreshing after navigating on successful campaign create.
    // Previously refreshing would retain the sortField from the router state.
    // If desired to retain remove the check for previousNavigation
    const state = this.router.getCurrentNavigation()?.extras.state;
    const previousNavigation =
      this.router.getCurrentNavigation()?.previousNavigation;
    if (state && previousNavigation) {
      if (state['alertType']) {
        const alertType = state['alertType'];
        const alertText = state['alertText'];
        this.alert = {
          type: alertType,
          text: alertText,
          visible: true,
        };
      }
      const { sortField, sortDirection } = this;
      this.updateColumnSortState(sortField, sortDirection);
    }

    // set the grids column definitions
    this.setGridColumnDefs();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async add() {
    await this.router.navigateByUrl('/campaigns/create');
  }

  dismissAlert() {
    this.alert = {
      text: '',
      type: AlertType.SuccessCreate,
      visible: false,
    };
  }

  getNextDataSet(params: IGetRowsParams) {
    this.showLoading();
    this.gridOptions?.api?.showLoadingOverlay();

    const requestBody: any = {
      startRow: params.startRow,
      endRow: params.endRow,
      maxRows: this.gridOptions.cacheBlockSize,
      searchTerm: this.searchTerm,
    };

    if (params?.sortModel?.length > 0) {
      // set the sortField in campaign service - this means they sorted by column
      this.campaignService.updateSortField(params.sortModel[0].colId);
      this.campaignService.updateSortDirection(params.sortModel[0].sort);

      requestBody.sortDirection = params.sortModel[0].sort;
      requestBody.sortField = params.sortModel[0].colId;
    } else {
      const { sortField, sortDirection } = this;

      requestBody.sortDirection = sortDirection;
      requestBody.sortField = sortField;
    }

    this.campaignService.getCampaigns(requestBody).subscribe({
      next: (data) => {
        let lastRow = -1;
        // if the length of the data is less than our cacheBlockSize it means we're on the last page
        // and we need to work out the lastRow
        if (
          data.length <
          (this.gridOptions.cacheBlockSize ??
            DEFAULT_GRID_OPTIONS.CACHE_BLOCK_SIZE)
        ) {
          lastRow = params.startRow + data.length;
        }
        // call the success callback
        params.successCallback(data, lastRow);
        this.gridOptions?.api?.hideOverlay();
        this.hideLoading();
      },
      error: (err) => {
        params.failCallback();
        this.gridOptions.api?.setRowCount(-1); // Need this otherwise an empty row displays
        this.gridOptions?.api?.showNoRowsOverlay(); // Show the "No Rows" overlay
        this.error = err.message;
        this.hideLoading();
      },
    });
  }

  async onCellKeyDown(e: any) {
    const event = e.event as KeyboardEvent;
    if (event?.key === 'Enter') {
      await this.onRowClick(e);
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridOptions.api = params.api;
    this.gridOptions.columnApi = params.columnApi;

    const dataSource: IDatasource = {
      getRows: (params: IGetRowsParams) => {
        this.getNextDataSet(params);
      },
    };

    params.api.setDatasource(dataSource);
  }

  async onRowClick(e: any) {
    if (e.data?.campaignId) {
      await this.router.navigateByUrl(`/campaigns/${e.data.campaignId}`);
    }
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }

  searchCampaigns() {
    this.campaignService.updateSearchTerm(this.searchTerm);

    // Trigger a data refresh
    this.gridOptions?.api?.onFilterChanged();
    this.gridOptions?.api?.refreshInfiniteCache();
  }

  /**
   * Set custom column properties used for the Campaigns grid
   */
  setGridColumnDefs() {
    this.gridOptions.columnDefs = [
      {
        headerName: this.translateService.instant(
          'CAMPAIGNS.GRID.HEADER_CAMPAIGN_ID'
        ),
        field: 'campaignId',
        cellClass: 'campaign-id',
        sort: this.sortField === 'campaignId' ? this.sortDirection : null,
      },
      {
        headerName: this.translateService.instant('CAMPAIGNS.GRID.HEADER_NAME'),
        field: 'name',
        cellClass: 'name',
        sort: this.sortField === 'name' ? this.sortDirection : null,
      },
      {
        headerName: this.translateService.instant(
          'CAMPAIGNS.GRID.HEADER_LOCATION_GROUP_ID'
        ),
        field: 'locationGroupId',
        cellClass: 'location-group-id',
        minWidth: 182,
        sort: this.sortField === 'locationGroupId' ? this.sortDirection : null,
      },
      {
        headerName: this.translateService.instant(
          'CAMPAIGNS.GRID.HEADER_SERVICE_CODE'
        ),
        field: 'selectedServiceCode',
        cellClass: 'service-code',
        minWidth: 150,
        sort:
          this.sortField === 'selectedServiceCode' ? this.sortDirection : null,
      },
      {
        headerName: this.translateService.instant(
          'CAMPAIGNS.GRID.HEADER_VACCINE_CODE'
        ),
        field: 'selectedVaccineCode',
        cellClass: 'vaccine-code',
        minWidth: 150,
        sort:
          this.sortField === 'selectedVaccineCode' ? this.sortDirection : null,
      },
      {
        headerName: this.translateService.instant(
          'CAMPAIGNS.GRID.HEADER_DEFAULT_ZIP'
        ),
        field: 'defaultZipCode',
        cellClass: 'default-zip-code',
        minWidth: 130,
        maxWidth: 200,
        sort: this.sortField === 'defaultZipCode' ? this.sortDirection : null,
      },
      {
        headerName: this.translateService.instant(
          'CAMPAIGNS.GRID.HEADER_STATUS'
        ),
        field: 'disabled',
        cellClass: 'status',
        cellDataType: 'boolean',
        minWidth: 115,
        maxWidth: 115,
        sort: this.sortField === 'status' ? this.sortDirection : null,
        cellRendererSelector: function (params: ICellRendererParams) {
          if (!params.data) {
            return;
          }

          const renderer = {
            component: StatusCellRendererComponent,
          };
          return renderer;
        },
      },
      {
        headerName: this.translateService.instant(
          'CAMPAIGNS.GRID.HEADER_QR_DOWNLOAD'
        ),
        field: 'qrCode',
        headerClass: 'text-primary text-center',
        cellClass: 'qr-code text-center',
        wrapHeaderText: true,
        sortable: false,
        minWidth: 112,
        maxWidth: 112,
        cellRendererSelector: function (params: ICellRendererParams) {
          if (!params.data) {
            return;
          }

          const renderer = {
            component: DownloadCellRendererComponent,
          };
          return renderer;
        },
      },
      {
        headerName: this.translateService.instant(
          'CAMPAIGNS.GRID.HEADER_DATE_CREATED'
        ),
        field: 'createdDate',
        cellClass: 'date-created',
        sort: this.sortField === 'createdDate' ? this.sortDirection : null,
        cellRenderer: function (params: any) {
          if (!params.data || !params.value) {
            return;
          }
          return DateTime.fromISO(params.value).toFormat(
            "MMM d, yyyy 'at' h:mm a"
          );
        },
      },
      {
        headerName: this.translateService.instant(
          'CAMPAIGNS.GRID.HEADER_DATE_UPDATED'
        ),
        field: 'updatedDate',
        cellClass: 'date-updated',
        sort: this.sortField === 'updatedDate' ? this.sortDirection : null,
        cellRenderer: function (params: any) {
          if (!params.data || !params.value) {
            return;
          }
          return DateTime.fromISO(params.value).toFormat(
            "MMM d, yyyy 'at' h:mm a"
          );
        },
      },
    ];
  }

  /**
   * Update the current column sort state
   * @param column - one of the column field names
   * @param direction - asc or desc
   */
  updateColumnSortState(column: string, direction: SortDirection) {
    this.campaignService.updateSortField(column);
    this.campaignService.updateSortDirection(direction);

    this.gridOptions.columnApi?.applyColumnState({
      state: [{ colId: column, sort: direction }],
      defaultState: { sort: null },
    });

    this.gridOptions.api?.onSortChanged();
  }
}
