import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationGroup } from '../common/model/location-group';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ApiError } from '../common/model/api-error';
import { Router, ActivatedRoute } from '@angular/router';
import { LocationGroupService } from '../common/services/location-group/location-group.service';
import { NavigationComponent } from '../navigation/navigation.component';
import { AlertType } from '../common/constants/alert';
import { DateTime } from 'luxon';
import { LogoExampleComponent } from '../components/logo-example/logo-example.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';

@Component({
  selector: 'app-location-group',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NavigationComponent,
    LogoExampleComponent,
    AccordionModule,
  ],
  templateUrl: './location-group.component.html',
  styleUrls: ['./location-group.component.scss'],
})
export class LocationGroupComponent implements OnInit {
  locationGroup?: LocationGroup;
  locationGroupId?: LocationGroup['locationGroupId'];
  form: FormGroup = new FormGroup({});
  isReadOnly = false;
  error?: ApiError;
  loading = false;

  get dateCreated() {
    const { locationGroup } = this;
    if (locationGroup?.createdDate) {
      return DateTime.fromISO(locationGroup.createdDate).toFormat("MMM d, yyyy 'at' h:mm a");
    }

    return '--';
  }

  get dateUpdated() {
    const { locationGroup } = this;
    if (locationGroup?.updatedDate) {
      return DateTime.fromISO(locationGroup.updatedDate).toFormat("MMM d, yyyy 'at' h:mm a");
    }

    return '--';
  }

  get status() {
    // status is the opposite of our disabled field
    return !this.form.get('disabled')?.value;
  }

  set status(disabled: boolean) {
    this.form.get('disabled')?.setValue(!disabled);
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private locationGroupService: LocationGroupService
  ) {}

  ngOnInit() {
    this.route.params
      .subscribe((param) => {
        const id = param['locationGroupId'];
        if (id !== 'create') {
          this.locationGroupId = id;
        }

        if (this.locationGroupId) {
          this.isReadOnly = true;
          this.getLocationGroup();
        } else {
          this.isReadOnly = false;
        }
      })
      .unsubscribe();

    this.setFormData();
  }

  async cancel() {
    await this.router.navigateByUrl('/pharmacy-groups');
  }

  /**
   * @description
   * Get location group details based on known locationGroupId.
   * This is used to pre-populate the form
   */
  getLocationGroup() {
    this.showLoading();

    const { locationGroupId } = this;
    if (locationGroupId) {
      this.locationGroupService.getLocationGroup(locationGroupId).subscribe({
        next: (res) => {
          this.hideLoading();
          if (res) {
            this.locationGroup = res;
            this.setFormData();
          }
        },
        error: () => {
          this.hideLoading();
        },
      });
    }
  }

  isFieldInvalid(field: string) {
    const control = this.form.get(field);
    return control?.invalid && (control.dirty || control.touched);
  }

  getErrorMessage(field: string) {
    const control = this.form.get(field);
    if (control?.errors) {
      const errors = control.errors;
      if (errors['required']) {
        return `Field is required`;
      }
    }
    return '';
  }

  save() {
    if (!this.form.valid) {
      return;
    }

    // Show loading
    this.showLoading();

    // Reset errors;
    this.error = undefined;

    // Convert the form data for submission
    const locationGroup: LocationGroup = new LocationGroup(this.form.getRawValue());

    /**
     * Determine if this is a new campaign or update based on the presence of a campaignId
     */
    if (this.locationGroupId) {
      this.locationGroupService.updateLocationGroup(locationGroup).subscribe({
        next: (res) => {
          this.hideLoading();

          if (res) {
            this.locationGroupService.updateSortDirection('desc');
            this.locationGroupService.updateSortField('updatedDate');

            this.router.navigateByUrl('/pharmacy-groups', {
              state: {
                alertType: AlertType.SuccessUpdate,
                alertText: 'LOCATION_GROUP.ALERT_UPDATE_SUCCESS',
              },
            });
          }
        },
        error: (err) => {
          this.hideLoading();
          this.error = err.error;
          this.setErrorsOnFormFields();
        },
      });
    } else {
      this.locationGroupService.createLocationGroup(locationGroup).subscribe({
        next: (res) => {
          this.hideLoading();

          if (res) {
            this.locationGroupService.updateSortDirection('desc');
            this.locationGroupService.updateSortField('createdDate');

            this.router.navigateByUrl('/pharmacy-groups', {
              state: {
                alertType: AlertType.SuccessCreate,
                alertText: 'LOCATION_GROUP.ALERT_CREATE_SUCCESS',
              },
            });
          }
        },
        error: (err) => {
          this.hideLoading();
          this.error = err.error;
          this.setErrorsOnFormFields();
        },
      });
    }
  }

  setErrorsOnFormFields() {
    if (this.error) {
      this.error.details?.forEach((error) => {
        const control = this.form.get(error.name);
        if (control) {
          control.setErrors({ backendError: error.reason });
          control.markAsTouched();
        }
      });
    }
  }

  /**
   * Sets the form controls with their default values and state
   */
  setFormData() {
    this.form = new FormGroup({
      locationGroupId: new FormControl({
        value: this.locationGroup?.locationGroupId ?? undefined,
        disabled: true,
      }),
      name: new FormControl(
        {
          value: this.locationGroup?.name ?? undefined,
          disabled: false,
        },
        [Validators.required]
      ),
      bookingUrl: new FormControl({
        value: this.locationGroup?.bookingUrl ?? undefined,
        disabled: false,
      }),
      informationalUrl: new FormControl({
        value: this.locationGroup?.informationalUrl ?? undefined,
        disabled: false,
      }),
      supportsScheduling: new FormControl({
        value: this.locationGroup?.supportsScheduling ?? false,
        disabled: this.isReadOnly,
      }),
      disabled: new FormControl(this.locationGroup?.disabled ?? false),
    });
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }
}
