import { Theme } from './theme';

export interface Campaign {
  campaignId?: string;
  name?: string;
  locationGroupId?: string;
  locationGroupParentId?: string;
  defaultZipCode?: string;
  defaultLocale?: string;
  selectedServiceCode?: string;
  selectedVaccineCode?: string;
  serviceLocked: boolean;
  serviceOverrideList: string;
  schedulingWindowDays?: number;
  disabled: boolean;
  theme: Theme;
  createdDate: string;
  updatedDate: string;
}

export class Campaign implements Campaign {
  campaignId?: string;
  name?: string;
  locationGroupId?: string;
  locationGroupParentId?: string;
  defaultZipCode?: string;
  defaultLocale?: string;
  selectedServiceCode?: string;
  selectedVaccineCode?: string;
  serviceLocked: boolean;
  serviceOverrideList: string;
  schedulingWindowDays?: number;
  disabled: boolean;
  theme: Theme;
  createdDate: string;
  updatedDate: string;

  constructor(campaign: Campaign) {
    this.campaignId = campaign.campaignId ? campaign.campaignId : undefined;
    this.name = campaign.name ? campaign.name : undefined;
    this.locationGroupId = campaign.locationGroupId ? campaign.locationGroupId : undefined;
    this.locationGroupParentId = campaign.locationGroupParentId ?? undefined;
    this.defaultZipCode = campaign.defaultZipCode ? campaign.defaultZipCode : undefined;
    this.defaultLocale = campaign.defaultLocale ? campaign.defaultLocale : undefined;
    this.selectedServiceCode = campaign.selectedServiceCode
      ? campaign.selectedServiceCode
      : undefined;
    this.selectedVaccineCode = campaign.selectedVaccineCode
      ? campaign.selectedVaccineCode
      : undefined;
    this.serviceLocked = campaign.serviceLocked ?? false;
    this.serviceOverrideList = campaign.serviceOverrideList ?? 'services';
    this.schedulingWindowDays = campaign.schedulingWindowDays
      ? campaign.schedulingWindowDays
      : undefined;
    this.disabled = campaign.disabled ?? false;
    this.theme = campaign.theme ?? undefined;
    this.createdDate = campaign.createdDate ?? undefined;
    this.updatedDate = campaign.updatedDate ?? undefined;
  }
}
