import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-theme-example-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './theme-example-button.component.html',
  styleUrls: ['./theme-example-button.component.scss'],
})
export class ThemeExampleButtonComponent {
  @Input() tintColor = '';
  @Input() tintActiveColor = '';
  @Input() time = '';

  isExampleButtonHovered = false;
}
