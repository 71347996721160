<nav>
  <div
    id="navigation-toggle-container"
    [style.justify-content]="(isDrawerOpen$ | async) ? 'flex-end' : 'center'"
    [style.padding-right.px]="(isDrawerOpen$ | async) ? '13' : '0'"
  >
    <button
      tabindex="0"
      role="button"
      type="button"
      [attr.aria-label]="'NAVIGATION.TOGGLE' | translate"
      class="btn btn-clear btn-icon-only"
      (click)="toggleNavDrawer()"
    >
      <img
        [src]="
          (isDrawerOpen$ | async)
            ? 'assets/icons/menu-arrow-close.svg'
            : 'assets/icons/menu-arrow-open.svg'
        "
        alt="Menu Toggle"
      />
    </button>
  </div>

  <ul id="main-nav">
    <li>
      <a
        class="nav-link align-items-center text-bold text-uppercase"
        routerLink="/campaigns"
        routerLinkActive="active"
        tabindex="0"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3 11H11V3H3V11ZM5 5H9V9H5V5ZM3 21H11V13H3V21ZM5 15H9V19H5V15ZM13 3V11H21V3H13ZM19 9H15V5H19V9ZM13.01 13H15.01V15H13.01V13ZM15.01 15H17.01V17H15.01V15ZM13.01 17H15.01V19H13.01V17ZM17.01 17H19.01V19H17.01V17ZM19.01 19H21.01V21H19.01V19ZM15.01 19H17.01V21H15.01V19ZM17.01 13H19.01V15H17.01V13ZM19.01 15H21.01V17H19.01V15Z"
          />
        </svg>
        <span *ngIf="isDrawerOpen$ | async">{{ 'NAV.CAMPAIGNS' | translate }}</span>
      </a>
    </li>
    <li>
      <a
        class="nav-link align-items-center text-bold text-uppercase"
        routerLink="/pharmacy-groups"
        routerLinkActive="active"
        tabindex="0"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M22 5C22 3.346 20.654 2 19 2H5C3.346 2 2 3.346 2 5V7.831C2 8.884 2.382 9.841 3 10.577V19C3 20.103 3.897 21 5 21H19C20.103 21 21 20.103 21 19V10.576C21.618 9.841 22 8.884 22 7.83V5ZM20 5V7.831C20 8.971 19.151 9.943 18.109 9.998L18 10C16.897 10 16 9.103 16 8V4H19C19.552 4 20 4.449 20 5ZM10 4H14V8C14 9.103 13.103 10 12 10C10.897 10 10 9.103 10 8V4ZM4 5C4 4.449 4.448 4 5 4H8V8C8 9.103 7.103 10 6 10L5.891 9.997C4.849 9.943 4 8.971 4 7.831V5ZM10 19V16H14V19H10ZM16 19V16C16 14.897 15.103 14 14 14H10C8.897 14 8 14.897 8 16V19H5V11.869C5.254 11.936 5.517 11.98 5.787 11.994C6.38989 12.0267 6.99233 11.9221 7.54891 11.6881C8.10548 11.4541 8.60165 11.0967 9 10.643C9.733 11.475 10.807 12 12 12C13.193 12 14.267 11.475 15 10.643C15.3983 11.0967 15.8945 11.4541 16.4511 11.6881C17.0077 11.9221 17.6101 12.0267 18.213 11.994C18.484 11.98 18.746 11.936 19 11.869V19H16Z"
          />
        </svg>
        <span *ngIf="isDrawerOpen$ | async">{{ 'NAV.LOCATION_GROUPS' | translate }}</span>
      </a>
    </li>
  </ul>

  <div id="navigation-footer">
    <button class="nav-link align-items-center text-bold text-uppercase" (click)="logout()">
      {{ 'NAV.LOGOUT' | translate }}
    </button>
  </div>
</nav>

<app-alert-prompt
  *ngIf="isAlertPromptVisible$ | async"
  [titleTC]="alertPromptLogout.titleTC"
  [subtitleTC]="alertPromptLogout.subtitleTC"
  [cancelTextTC]="alertPromptLogout.cancelTextTC"
  [continueTextTC]="alertPromptLogout.continueTextTC"
  (cancelAction)="alertPromptCancel()"
  (continueAction)="alertPromptContinue()"
></app-alert-prompt>
