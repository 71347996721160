<div
  id="alert"
  [class]="alertClass"
  class="alert d-flex align-items-center alert-dismissible fade show"
  role="alert"
>
  <img [src]="alertIconPath" alt="Alert Icon" />
  <div>{{ alertText | translate }}</div>
  <button
    type="button"
    class="btn btn-close"
    data-bs-dismiss="alert"
    [attr.aria-label]="'GLOBAL.CLOSE' | translate"
  ></button>
</div>
