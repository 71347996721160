export interface LocationGroup {
  locationGroupId: string;
  name?: string;
  adapterFunction?: string;
  bookingUrl?: string;
  informationalUrl?: string;
  supportsScheduling: boolean;
  disabled: boolean;
  logoUrl: string;
  createdDate: string;
  updatedDate: string;
}

export class LocationGroup implements LocationGroup {
  locationGroupId: string;
  name?: string;
  adapterFunction?: string;
  bookingUrl?: string;
  informationalUrl?: string;
  supportsScheduling: boolean;
  disabled: boolean;
  logoUrl: string;
  createdDate: string;
  updatedDate: string;

  constructor(locationGroup: LocationGroup) {
    this.locationGroupId = locationGroup.locationGroupId ?? undefined;
    this.name = locationGroup.name ? locationGroup.name : undefined;
    this.adapterFunction = !locationGroup.adapterFunction
      ? locationGroup.adapterFunction
      : undefined;
    this.bookingUrl = locationGroup.bookingUrl ? locationGroup.bookingUrl : undefined;
    this.informationalUrl = locationGroup.informationalUrl
      ? locationGroup.informationalUrl
      : undefined;
    this.supportsScheduling = locationGroup.supportsScheduling ?? false;
    this.disabled = locationGroup.disabled ?? false;
    this.logoUrl = locationGroup.logoUrl ?? undefined;
    this.createdDate = locationGroup.createdDate ?? undefined;
    this.updatedDate = locationGroup.updatedDate ?? undefined;
  }
}
