export const environment = {
  name: 'qa',
  production: false,
  version: '1.3.0',
  siteUrlWithSlash: 'iyliwrdtbmehfbm.easyvax.org/',
  apiBaseUrl: 'https://admin.iyliwrdtbmehfbm.easyvax.org/api',
  apiKey: 'piAvblLcz9SX?}[j',
  msalConfig: {
    auth: {
      clientId: 'abc985e5-b3b9-426b-96d3-ccfcd6f3dce8',
      authority: 'https://login.microsoftonline.com/98ee3710-134f-4724-b5e5-9cf7809f8bad',
    },
  },
  apiConfig: {
    scopes: ['api://admin-api-backend-qa/.default'],
    uri: 'https://admin.iyliwrdtbmehfbm.easyvax.org/api',
  },
};
