<main class="container-fluid p-0">
  <div id="campaign-container" class="py-4 px-3 px-md-5">
    <header class="mb-4 d-flex justify-content-between">
      <h3 class="text-primary">
        {{
          campaignId ? ('CAMPAIGN.TITLE_EDIT' | translate) : ('CAMPAIGN.TITLE_CREATE' | translate)
        }}
      </h3>

      <!-- STATUS -->
      <div id="status-container" class="d-flex flex-row align-self-center">
        <label
          [innerText]="'CAMPAIGN.FORM.FIELD.STATUS' | translate"
          class="text-secondary text-capitalize me-2 text-bold"
          for="status"
        ></label>
        <div class="form-check form-switch">
          <span
            [class.active]="form.get('disabled')?.value === false"
            [class.inactive]="form.get('disabled')?.value === true"
          >
            {{
              form.get('disabled')?.value
                ? ('CAMPAIGN.FORM.FIELD.STATUS_FALSE' | translate)
                : ('CAMPAIGN.FORM.FIELD.STATUS_TRUE' | translate)
            }}
          </span>

          <input
            class="form-check-input toggle text-primary text-semibold"
            role="switch"
            type="checkbox"
            id="status"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="status"
            aria-label="Campaign Status"
            autocomplete="off"
          />
        </div>
      </div>
    </header>

    <form role="form" id="campaign-form" aria-label="Campaign Form" class="row" [formGroup]="form">
      <div class="col-sm-12 col-lg-4">
        <accordion>
          <!-- 
            Setup 
          -->
          <accordion-group panelClass="accordion-panel" #section1 [isOpen]="true">
            <button
              class="btn btn-link btn-block justify-content-between align-items-center d-flex w-100 shadow-none"
              accordion-heading
              type="button"
            >
              {{ 'CAMPAIGN.SECTION_1_TITLE' | translate }}

              <svg
                class="icon"
                [class.open]="section1.isOpen"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M16.293 9.29303L12 13.586L7.70697 9.29303L6.29297 10.707L12 16.414L17.707 10.707L16.293 9.29303Z"
                  fill="currentColor"
                />
              </svg>
            </button>

            <!-- CAMPAIGN ID -->
            <label
              class="text-secondary text-capitalize mb-1 text-bold"
              for="campaign-id"
              [innerText]="'CAMPAIGN.FORM.FIELD.CAMPAIGN_ID' | translate"
            ></label>

            <p
              class="text-tertiary"
              [innerText]="'CAMPAIGN.FORM.FIELD.CAMPAIGN_ID_DESCRIPTION' | translate"
            ></p>

            <input
              type="text"
              id="campaign-id"
              [class.form-field-error]="isFieldInvalid('campaignId')"
              formControlName="campaignId"
              class="form-control text-primary mb-2 text-semibold"
            />

            <p class="text-tertiary m-0 text-wrap" *ngIf="form.get('campaignId')?.value">
              URL:
              <span class="text-bold text-break">{{
                siteUrl + form.get('campaignId')?.value
              }}</span>
            </p>

            <div *ngIf="isFieldInvalid('campaignId')" class="text-danger d-flex flex-column mb-2">
              <span>{{ getErrorMessage('campaignId') }}</span>
              <span *ngIf="form.get('campaignId')?.errors?.['backendError']">
                {{ form.get('campaignId')?.errors?.['backendError'] }}
              </span>
            </div>

            <!-- NAME -->
            <label class="text-secondary text-capitalize mt-3 mb-1 text-bold" for="name">
              {{ 'CAMPAIGN.FORM.FIELD.NAME' | translate }}
              <span class="text-danger">*</span>
            </label>

            <p
              class="text-tertiary"
              [innerText]="'CAMPAIGN.FORM.FIELD.NAME_DESCRIPTION' | translate"
            ></p>

            <div class="position-relative">
              <input
                type="text"
                id="name"
                [class.form-field-error]="isFieldInvalid('name')"
                formControlName="name"
                class="form-control text-primary mb-2 text-semibold"
              />

              <div *ngIf="isFieldInvalid('name')" class="text-danger d-flex flex-column mb-2">
                <img class="form-field-error-icon" src="assets/icons/error.svg" alt="Error icon" />
                <span>{{ getErrorMessage('name') }}</span>
                <span *ngIf="form.get('name')?.errors?.['backendError']">
                  {{ form.get('name')?.errors?.['backendError'] }}
                </span>
              </div>
            </div>

            <p
              [innerText]="'CAMPAIGN.FORM.FIELD.DEFAULT_LOCALE' | translate"
              class="text-secondary text-capitalize mt-3 mb-1 text-bold"
            ></p>

            <p
              class="text-tertiary"
              [innerText]="'CAMPAIGN.FORM.FIELD.DEFAULT_LOCALE_DESCRIPTION' | translate"
            ></p>

            <div class="position-relative">
              <select
                formControlName="defaultLocale"
                class="form-select text-primary mb-2 text-semibold"
                [class.form-field-error]="isFieldInvalid('defaultLocale')"
                aria-label="Default locale select"
              >
                <option value="undefined" selected>
                  {{ 'CAMPAIGN.FORM.FIELD.DEFAULT_LOCALE_PLACEHOLDER' | translate }}
                </option>
                <option *ngFor="let language of languages" [value]="language.localeCode">
                  {{ language.localeTC | translate }}
                </option>
              </select>

              <div
                *ngIf="isFieldInvalid('defaultLocale')"
                class="text-danger d-flex flex-column mb-2"
              >
                <span>{{ getErrorMessage('defaultLocale') }}</span>
                <span *ngIf="form.get('defaultLocale')?.errors?.['backendError']">
                  {{ form.get('defaultLocale')?.errors?.['backendError'] }}
                </span>
              </div>
            </div>
          </accordion-group>

          <!--
          Service & Vaccine Customization
        -->
          <accordion-group #section6 panelClass="accordion-panel">
            <button
              class="btn btn-link btn-block justify-content-between align-items-center d-flex w-100 shadow-none"
              accordion-heading
              type="button"
            >
              {{ 'CAMPAIGN.SECTION_6_TITLE' | translate }}

              <svg
                class="icon"
                [class.open]="section6.isOpen"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M16.293 9.29303L12 13.586L7.70697 9.29303L6.29297 10.707L12 16.414L17.707 10.707L16.293 9.29303Z"
                  fill="currentColor"
                />
              </svg>
            </button>

            <p
              [innerText]="'CAMPAIGN.FORM.FIELD.SERVICE_OVERRIDE_LIST' | translate"
              class="text-secondary text-capitalize mb-1 text-bold"
            ></p>

            <!-- Services List -->
            <div class="position-relative">
              <select
                formControlName="serviceOverrideList"
                class="form-select text-primary mb-2 text-semibold"
                [class.form-field-error]="isFieldInvalid('serviceOverrideList')"
                (change)="servicesListValueChanged($event)"
                aria-label="Service override list select"
              >
                <option *ngFor="let list of servicesList" [value]="list.id">
                  {{ list.name }}
                </option>
              </select>

              <div
                *ngIf="isFieldInvalid('serviceOverrideList')"
                class="text-danger d-flex flex-column mb-2"
              >
                <span>{{ getErrorMessage('serviceOverrideList') }}</span>
                <span *ngIf="form.get('serviceOverrideList')?.errors?.['backendError']">
                  {{ form.get('serviceOverrideList')?.errors?.['backendError'] }}
                </span>
              </div>
            </div>

            <!-- SERVICE CODE -->
            <ng-container
              *ngIf="serviceGroups && form.controls['serviceOverrideList'].value && !loading"
            >
              <hr />

              <p
                [innerText]="'CAMPAIGN.FORM.FIELD.DEFAULT_SERVICE' | translate"
                class="text-secondary text-capitalize mt-4 mb-1 text-bold"
              ></p>

              <p
                class="text-tertiary"
                [innerText]="'CAMPAIGN.FORM.FIELD.DEFAULT_SERVICE_DESCRIPTION' | translate"
              ></p>

              <ng-container *ngFor="let group of serviceGroups">
                <p class="text-uppercase text-semibold ps-3 mb-0 mt-4">
                  {{ group.categoryNameTC | translate }}
                </p>
                <ul class="d-flex flex-column mt-2 ps-3">
                  <li
                    class="d-flex align-items-center gap-2"
                    *ngFor="let service of group?.services"
                  >
                    <input
                      class="form-check-input m-0"
                      type="radio"
                      role="checkbox"
                      [id]="'service-' + service.serviceCode"
                      [value]="service.serviceCode"
                      [checked]="form.controls['selectedServiceCode'].value === service.serviceCode"
                      [attr.aria-checked]="
                        form.controls['selectedServiceCode'].value === service.serviceCode
                      "
                      [attr.aria-label]="service.serviceNameTC | translate"
                      tabindex="-1"
                      (click)="serviceValueChanged($event)"
                    />
                    <label [for]="'service-' + service.serviceCode" class="form-check-label">
                      {{ service.serviceNameTC | translate }}
                      <span class="d-flex text-tertiary">
                        {{ service.serviceDescriptionTC | translate }}
                      </span>
                    </label>
                  </li>
                </ul>
              </ng-container>

              <div
                *ngIf="isFieldInvalid('selectedServiceCode')"
                class="text-danger d-flex flex-column mb-2"
              >
                <span>{{ getErrorMessage('selectedServiceCode') }}</span>
                <span *ngIf="form.get('selectedServiceCode')?.errors?.['backendError']">
                  {{ form.get('selectedServiceCode')?.errors?.['backendError'] }}
                </span>
              </div>
            </ng-container>

            <ng-container *ngIf="form.controls['selectedServiceCode'].value && !loading">
              <p
                class="text-tertiary mt-5"
                [innerText]="'CAMPAIGN.FORM.FIELD.SERVICE_LOCKED_DESCRIPTION' | translate"
              ></p>

              <div id="service-locked-container" class="d-flex flex-row align-self-center mb-2">
                <label
                  [innerText]="'CAMPAIGN.FORM.FIELD.SERVICE_LOCKED' | translate"
                  class="text-secondary text-capitalize me-2 text-bold"
                  for="service-locked"
                ></label>

                <div class="form-check form-switch">
                  <input
                    class="form-check-input toggle text-primary text-semibold"
                    role="switch"
                    type="checkbox"
                    id="service-locked"
                    formControlName="serviceLocked"
                    aria-label="Service Locked for Campaign"
                    autocomplete="off"
                  />
                </div>
              </div>
            </ng-container>

            <ng-container
              *ngIf="form.controls['selectedServiceCode'].value && vaccines && !loading"
            >
              <hr />

              <!-- VACCINE CODE -->
              <p
                [innerText]="'CAMPAIGN.FORM.FIELD.DEFAULT_VACCINE' | translate"
                class="text-secondary text-capitalize mt-3 mb-1 text-bold"
              ></p>

              <p
                class="text-tertiary"
                [innerText]="'CAMPAIGN.FORM.FIELD.DEFAULT_VACCINE_DESCRIPTION' | translate"
              ></p>

              <ul class="d-flex flex-column mt-2 ps-3">
                <li class="d-flex align-items-center gap-2" *ngFor="let vaccine of vaccines">
                  <input
                    class="form-check-input m-0"
                    type="radio"
                    role="checkbox"
                    [id]="'vaccine-' + vaccine.vaccineCode"
                    [value]="vaccine.vaccineCode"
                    [checked]="form.controls['selectedVaccineCode'].value === vaccine.vaccineCode"
                    [attr.aria-checked]="
                      form.controls['selectedVaccineCode'].value === vaccine.vaccineCode
                    "
                    [attr.aria-label]="vaccine.vaccineNameTC | translate"
                    tabindex="-1"
                    (click)="vaccineValueChanged($event)"
                  />
                  <label [for]="'vaccine-' + vaccine.vaccineCode" class="form-check-label">{{
                    vaccine.vaccineNameTC | translate
                  }}</label>
                </li>
              </ul>

              <div
                *ngIf="isFieldInvalid('selectedVaccineCode')"
                class="text-danger d-flex flex-column mb-2"
              >
                <span>{{ getErrorMessage('selectedVaccineCode') }}</span>
                <span *ngIf="form.get('selectedVaccineCode')?.errors?.['backendError']">
                  {{ form.get('selectedVaccineCode')?.errors?.['backendError'] }}
                </span>
              </div>
            </ng-container>
          </accordion-group>
        </accordion>
      </div>

      <div class="col-sm-12 col-lg-8">
        <accordion>
          <!-- Location Search Customization -->
          <accordion-group #section3 panelClass="accordion-panel">
            <button
              class="btn btn-link btn-block justify-content-between align-items-center d-flex w-100 shadow-none"
              accordion-heading
              type="button"
            >
              {{ 'CAMPAIGN.SECTION_3_TITLE' | translate }}

              <svg
                class="icon"
                [class.open]="section3.isOpen"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M16.293 9.29303L12 13.586L7.70697 9.29303L6.29297 10.707L12 16.414L17.707 10.707L16.293 9.29303Z"
                  fill="currentColor"
                />
              </svg>
            </button>

            <!-- ZIP -->
            <label
              [innerText]="'CAMPAIGN.FORM.FIELD.ZIP' | translate"
              class="text-secondary text-capitalize mb-1 text-bold"
              for="zip-code"
            ></label>
            <p
              class="text-tertiary"
              [innerText]="'CAMPAIGN.FORM.FIELD.ZIP_DESCRIPTION' | translate"
            ></p>

            <div class="position-relative">
              <input
                type="text"
                id="zip-code"
                formControlName="defaultZipCode"
                [class.form-field-error]="isFieldInvalid('defaultZipCode')"
                class="form-control text-primary mb-2 text-semibold"
              />

              <div
                *ngIf="isFieldInvalid('defaultZipCode')"
                class="text-danger d-flex flex-column mb-2"
              >
                <img class="form-field-error-icon" src="assets/icons/error.svg" alt="Error icon" />
                <span>{{ getErrorMessage('defaultZipCode') }}</span>
                <span *ngIf="form.get('defaultZipCode')?.errors?.['backendError']">
                  {{ form.get('defaultZipCode')?.errors?.['backendError'] }}
                </span>
              </div>
            </div>

            <!-- SCHEDULING WINDOW DAYS -->
            <label
              [innerText]="'CAMPAIGN.FORM.FIELD.SCHEDULING_WINDOW_DAYS' | translate"
              class="text-secondary text-capitalize mt-3 mb-1 text-bold"
              for="scheduling-window-days"
            ></label>

            <p
              class="text-tertiary"
              [innerText]="'CAMPAIGN.FORM.FIELD.SCHEDULING_WINDOW_DAYS_DESCRIPTION' | translate"
            ></p>

            <div class="position-relative">
              <input
                [class.form-field-error]="isFieldInvalid('schedulingWindowDays')"
                type="number"
                id="scheduling-window-days"
                formControlName="schedulingWindowDays"
                class="form-control text-primary mb-2 text-semibold"
              />

              <div
                *ngIf="isFieldInvalid('schedulingWindowDays')"
                class="text-danger d-flex flex-column mb-2"
              >
                <img class="form-field-error-icon" src="assets/icons/error.svg" alt="Error icon" />
                <span>{{ getErrorMessage('schedulingWindowDays') }}</span>
                <span *ngIf="form.get('schedulingWindowDays')?.errors?.['backendError']">
                  {{ form.get('schedulingWindowDays')?.errors?.['backendError'] }}
                </span>
              </div>
            </div>
          </accordion-group>

          <!-- 
          LOCATION GROUP 
        -->
          <accordion-group #section2 panelClass="accordion-panel">
            <button
              class="btn btn-link btn-block justify-content-between align-items-center d-flex w-100 shadow-none"
              accordion-heading
              type="button"
            >
              {{ 'CAMPAIGN.SECTION_2_TITLE' | translate }}

              <svg
                class="icon"
                [class.open]="section2.isOpen"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M16.293 9.29303L12 13.586L7.70697 9.29303L6.29297 10.707L12 16.414L17.707 10.707L16.293 9.29303Z"
                  fill="currentColor"
                />
              </svg>
            </button>

            <p class="text-tertiary" [innerText]="'CAMPAIGN.SECTION_2_DESCRIPTION' | translate"></p>

            <!-- LOCATION GROUP PARENT -->
            <label
              [innerText]="'CAMPAIGN.FORM.FIELD.PHARMACY_GROUP_PARENT' | translate"
              class="text-secondary mb-1 text-bold"
              for="location-group-parent-id"
            ></label>

            <p
              class="text-tertiary"
              [innerText]="'CAMPAIGN.FORM.FIELD.PHARMACY_GROUP_PARENT_DESCRIPTION' | translate"
            ></p>

            <select
              id="location-group-parent-id"
              formControlName="locationGroupParentId"
              (change)="locationGroupParentValueChanged($event)"
              class="form-select text-primary mb-2 text-semibold"
              [class.form-field-error]="isFieldInvalid('locationGroupParentId')"
              aria-label="Associated Pharmacy Group Parent select"
            >
              <option value="undefined" selected>
                {{ 'CAMPAIGN.FORM.FIELD.PHARMACY_GROUP_PARENT_PLACEHOLDER' | translate }}
              </option>
              <option *ngFor="let location of locationGroupParents" [value]="location.id">
                {{ location.name }}
              </option>
            </select>

            <div
              *ngIf="isFieldInvalid('locationGroupParentId')"
              class="text-danger d-flex flex-column mb-2"
            >
              <span>{{ getErrorMessage('locationGroupParentId') }}</span>
              <span *ngIf="form.get('locationGroupParentId')?.errors?.['backendError']">
                {{ form.get('locationGroupParentId')?.errors?.['backendError'] }}
              </span>
            </div>

            <!-- LOCATION GROUP -->

            <label
              [innerText]="'CAMPAIGN.FORM.FIELD.PHARMACY_GROUP' | translate"
              class="text-secondary mb-1 mt-3 text-bold"
              for="location-group-id"
            ></label>

            <p
              class="text-tertiary"
              [innerText]="'CAMPAIGN.FORM.FIELD.PHARMACY_GROUP_DESCRIPTION' | translate"
            ></p>

            <select
              id="location-group-id"
              formControlName="locationGroupId"
              (change)="locationGroupValueChanged($event)"
              class="form-select text-primary mb-2 text-semibold"
              [class.form-field-error]="isFieldInvalid('locationGroupId')"
              aria-label="Associated Pharmacy Group select"
            >
              <option value="undefined" selected>
                {{ 'CAMPAIGN.FORM.FIELD.PHARMACY_GROUP_PLACEHOLDER' | translate }}
              </option>
              <option *ngFor="let location of locationGroups" [value]="location.locationGroupId">
                {{ location.name }} - {{ location.locationGroupId }}
              </option>
            </select>

            <div
              *ngIf="isFieldInvalid('locationGroupId')"
              class="text-danger d-flex flex-column mb-2"
            >
              <span>{{ getErrorMessage('locationGroupId') }}</span>
              <span *ngIf="form.get('locationGroupId')?.errors?.['backendError']">
                {{ form.get('locationGroupId')?.errors?.['backendError'] }}
              </span>
            </div>
          </accordion-group>

          <!-- 
          THEME 
        -->
          <accordion-group #section4 panelClass="accordion-panel">
            <button
              class="btn btn-link btn-block justify-content-between align-items-center d-flex w-100 shadow-none"
              accordion-heading
              type="button"
            >
              {{ 'CAMPAIGN.SECTION_4_TITLE' | translate }}

              <svg
                class="icon"
                [class.open]="section4.isOpen"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M16.293 9.29303L12 13.586L7.70697 9.29303L6.29297 10.707L12 16.414L17.707 10.707L16.293 9.29303Z"
                  fill="currentColor"
                />
              </svg>
            </button>

            <p class="text-tertiary" [innerText]="'CAMPAIGN.SECTION_4_DESCRIPTION' | translate"></p>
            <div class="row">
              <div class="col-lg-4">
                <p class="text-bold text-primary mt-3">
                  {{ 'CAMPAIGN.FORM.FIELD.THEME_LIGHT_MODE' | translate }}
                </p>
                <!-- THEME: Background Color -->
                <div class="d-flex align-items-center gap-2 mt-2" formGroupName="theme">
                  <input
                    type="color"
                    id="theme-background-color"
                    formControlName="backgroundColor"
                    class="form-control form-control-color text-primary mb-2 text-semibold p-0"
                    title="Background Color"
                  />
                  <label
                    class="text-secondary mb-1 d-flex flex-column"
                    for="theme-background-color"
                  >
                    {{ 'CAMPAIGN.FORM.FIELD.THEME_BACKGROUND_COLOR' | translate }}
                    <span class="text-primary mb-2 text-semibold">
                      {{ form.get('theme.backgroundColor')?.value }}
                    </span>
                  </label>
                </div>

                <!-- THEME: Text Color -->
                <div class="d-flex align-items-center gap-2 mt-2" formGroupName="theme">
                  <input
                    type="color"
                    id="theme-text-color"
                    formControlName="textColor"
                    class="form-control form-control-color text-primary mb-2 text-semibold p-0"
                    title="Text Color"
                  />
                  <label class="text-secondary mb-1 d-flex flex-column" for="theme-text-color">
                    {{ 'CAMPAIGN.FORM.FIELD.THEME_TEXT_COLOR' | translate }}
                    <span class="text-primary mb-2 text-semibold">
                      {{ form.get('theme.textColor')?.value }}
                    </span>
                  </label>
                </div>

                <!-- THEME: Tint Color -->
                <div class="d-flex align-items-center gap-2 mt-2" formGroupName="theme">
                  <input
                    type="color"
                    id="theme-tint-color"
                    formControlName="tintColor"
                    class="form-control form-control-color text-primary mb-2 text-semibold p-0"
                    title="Tint Color"
                  />
                  <label class="text-secondary mb-1 d-flex flex-column" for="theme-tint-color">
                    {{ 'CAMPAIGN.FORM.FIELD.THEME_TINT_COLOR' | translate }}
                    <span class="text-primary mb-2 text-semibold">
                      {{ form.get('theme.tintColor')?.value }}
                    </span>
                  </label>
                </div>

                <!-- THEME: Tint Active Color -->
                <div class="d-flex align-items-center gap-2 mt-2" formGroupName="theme">
                  <input
                    type="color"
                    id="theme-tint-active-color"
                    formControlName="tintActiveColor"
                    class="form-control form-control-color text-primary mb-2 text-semibold p-0"
                    title="Tint Active Color"
                  />
                  <label
                    class="text-secondary mb-1 d-flex flex-column"
                    for="theme-tint-active-color"
                  >
                    {{ 'CAMPAIGN.FORM.FIELD.THEME_TINT_ACTIVE_COLOR' | translate }}
                    <span class="text-primary mb-2 text-semibold">
                      {{ form.get('theme.tintActiveColor')?.value }}
                    </span>
                  </label>
                </div>
              </div>

              <div class="col-lg-8">
                <!-- LIGHT THEME EXAMPLE -->
                <app-theme-example
                  [backgroundColor]="form.get('theme.backgroundColor')?.value"
                  [textColor]="form.get('theme.textColor')?.value"
                  [tintColor]="form.get('theme.tintColor')?.value"
                  [tintActiveColor]="form.get('theme.tintActiveColor')?.value"
                ></app-theme-example>
              </div>
            </div>

            <!-- Dark Mode -->
            <div class="row mt-3">
              <div class="col-lg-4">
                <p class="text-bold text-primary mt-3">
                  {{ 'CAMPAIGN.FORM.FIELD.THEME_DARK_MODE' | translate }}
                </p>

                <div class="d-flex align-items-center gap-2 mt-2" formGroupName="theme">
                  <input
                    type="color"
                    id="theme-background-color-dark"
                    formControlName="backgroundColorDark"
                    class="form-control form-control-color text-primary mb-2 text-semibold p-0"
                    title="Background Color Dark"
                  />
                  <label
                    class="text-secondary mb-1 d-flex flex-column"
                    for="theme-background-color-dark"
                  >
                    {{ 'CAMPAIGN.FORM.FIELD.THEME_BACKGROUND_COLOR_DARK' | translate }}
                    <span class="text-primary mb-2 text-semibold">
                      {{ form.get('theme.backgroundColorDark')?.value }}
                    </span>
                  </label>
                </div>

                <div class="d-flex align-items-center gap-2 mt-2" formGroupName="theme">
                  <input
                    type="color"
                    id="theme-text-color-dark"
                    formControlName="textColorDark"
                    class="form-control form-control-color text-primary mb-2 text-semibold p-0"
                    title="Text Color Dark"
                  />
                  <label class="text-secondary mb-1 d-flex flex-column" for="theme-text-color-dark">
                    {{ 'CAMPAIGN.FORM.FIELD.THEME_TEXT_COLOR_DARK' | translate }}
                    <span class="text-primary mb-2 text-semibold">
                      {{ form.get('theme.textColorDark')?.value }}
                    </span>
                  </label>
                </div>

                <div class="d-flex align-items-center gap-2 mt-2" formGroupName="theme">
                  <input
                    type="color"
                    id="theme-tint-color-dark"
                    formControlName="tintColorDark"
                    class="form-control form-control-color text-primary mb-2 text-semibold p-0"
                    title="Tint Color Dark"
                  />
                  <label class="text-secondary mb-1 d-flex flex-column" for="theme-tint-color-dark">
                    {{ 'CAMPAIGN.FORM.FIELD.THEME_TINT_COLOR_DARK' | translate }}
                    <span class="text-primary mb-2 text-semibold">
                      {{ form.get('theme.tintColorDark')?.value }}
                    </span>
                  </label>
                </div>

                <div class="d-flex align-items-center gap-2 mt-2" formGroupName="theme">
                  <input
                    type="color"
                    id="theme-tint-active-color-dark"
                    formControlName="tintActiveColorDark"
                    class="form-control form-control-color text-primary mb-2 text-semibold p-0"
                    title="Tint Active Color Dark"
                  />
                  <label
                    class="text-secondary mb-1 d-flex flex-column"
                    for="theme-tint-active-color-dark"
                  >
                    {{ 'CAMPAIGN.FORM.FIELD.THEME_TINT_ACTIVE_COLOR_DARK' | translate }}
                    <span class="text-primary mb-2 text-semibold">
                      {{ form.get('theme.tintActiveColorDark')?.value }}
                    </span>
                  </label>
                </div>
              </div>

              <div class="col-lg-8">
                <app-theme-example
                  class="dark-mode"
                  [backgroundColor]="form.get('theme.backgroundColorDark')?.value"
                  [textColor]="form.get('theme.textColorDark')?.value"
                  [tintColor]="form.get('theme.tintColorDark')?.value"
                  [tintActiveColor]="form.get('theme.tintActiveColorDark')?.value"
                ></app-theme-example>
              </div>
            </div>
          </accordion-group>

          <!-- 
          QR CODE 
        -->
          <accordion-group #section5 panelClass="accordion-panel">
            <button
              class="btn btn-link btn-block justify-content-between align-items-center d-flex w-100 shadow-none"
              accordion-heading
              type="button"
            >
              {{ 'CAMPAIGN.SECTION_5_TITLE' | translate }}

              <svg
                class="icon"
                [class.open]="section5.isOpen"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M16.293 9.29303L12 13.586L7.70697 9.29303L6.29297 10.707L12 16.414L17.707 10.707L16.293 9.29303Z"
                  fill="currentColor"
                />
              </svg>
            </button>

            <div id="qr-code-container">
              <p class="text-tertiary text-italic">
                <span [innerText]="'CAMPAIGN.SECTION_5_DESCRIPTION' | translate"></span>
                <span
                  class="ms-2"
                  *ngIf="!qrCode && !isReadOnly"
                  [innerText]="'CAMPAIGN.SECTION_5_DISCLAIMER' | translate"
                ></span>
              </p>
              <div class="d-flex flex-column gap-3">
                <svg
                  *ngIf="!qrCode"
                  class="qr-example-icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 11H11V3H3V11ZM5 5H9V9H5V5ZM3 21H11V13H3V21ZM5 15H9V19H5V15ZM13 3V11H21V3H13ZM19 9H15V5H19V9ZM13.01 13H15.01V15H13.01V13ZM15.01 15H17.01V17H15.01V15ZM13.01 17H15.01V19H13.01V17ZM17.01 17H19.01V19H17.01V17ZM19.01 19H21.01V21H19.01V19ZM15.01 19H17.01V21H15.01V19ZM17.01 13H19.01V15H17.01V13ZM19.01 15H21.01V17H19.01V15Z"
                  />
                </svg>

                <img *ngIf="qrCode" id="qr-code-image" [src]="qrCode" alt="Campaign QR Code" />

                <p class="text-danger" *ngIf="!qrCode && isReadOnly">
                  {{ 'CAMPAIGN.QR_ERROR_DESCRIPTION' | translate }}
                  <a
                    class="link-underline"
                    role="button"
                    tabindex="0"
                    (click)="getCampaignQrCode()"
                    (keyup.enter)="getCampaignQrCode()"
                  >
                    {{ 'CAMPAIGN.QR_ERROR_RETRY' | translate }}
                  </a>
                </p>

                <button
                  [disabled]="!qrCode"
                  (click)="downloadQrCode()"
                  class="btn btn-outline-primary text-uppercase"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 16L16 11H13V4H11V11H8L12 16Z" fill="currentColor" />
                    <path
                      d="M20 18H4V11H2V18C2 19.103 2.897 20 4 20H20C21.103 20 22 19.103 22 18V11H20V18Z"
                      fill="currentColor"
                    />
                  </svg>

                  {{ 'CAMPAIGN.QR_BUTTON' | translate }}
                </button>
              </div>
            </div>
          </accordion-group>
        </accordion>
      </div>
    </form>

    <!-- CAMPAIGN EDIT HISTORY -->
    <div *ngIf="isReadOnly" class="d-flex mt-5 flex-column align-items-end">
      <div class="text-tertiary">
        {{ 'CAMPAIGN.DATE_CREATED' | translate }}
        {{ dateCreated }}
      </div>
      <div class="text-tertiary">
        {{ 'CAMPAIGN.DATE_UPDATED' | translate }}
        {{ dateUpdated }}
      </div>
    </div>
  </div>

  <footer>
    <div class="container d-flex gap-2 py-4 px-3 justify-content-around">
      <button
        [innerText]="'CAMPAIGN.FORM.BUTTON_CANCEL' | translate"
        (click)="cancel()"
        class="btn btn-outline-primary text-uppercase text-semibold d-flex flex-column flex-fill align-items-center w-100 h-100 justify-content-center"
      ></button>

      <button
        (click)="save()"
        [disabled]="!form.valid || loading"
        class="btn btn-primary text-uppercase text-semibold d-flex flex-column flex-fill align-items-center w-100 h-100 justify-content-center"
      >
        <div class="spinner-border" role="status" *ngIf="loading">
          <span class="visually-hidden">Loading...</span>
        </div>
        <span *ngIf="!loading">{{ 'CAMPAIGN.FORM.BUTTON_SAVE' | translate }}</span>
      </button>
    </div>
  </footer>
</main>

<app-navigation></app-navigation>
